import { Group, Menu, Text, UnstyledButton, createStyles } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React from 'react';
import i18n from '../i18n';
import { useMedplum } from '@medplum/react';
import { useTranslation } from 'react-i18next';
import { useAuthMeStore } from '../store/useAuthMeStore';

const useStyles = createStyles((theme) => ({
  logoButton: {
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background as string,
        0.8
      ),
    },
  },

  user: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background as string,
        0.8
      ),
    },
  },

  userName: {
    fontWeight: 500,
    lineHeight: 1,
    marginRight: 3,

    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  userActive: {
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background as string,
      0.8
    ),
  },
}));

interface Props {
  langMenuOpened: boolean;
  setLangMenuOpened: (val: boolean) => void;
  langImage: React.ReactNode;
  setLangImage: (val: string) => void;
  langvalue: string;
  setLangValue: (val: string) => void;
}

const LanguageSwitchMenu = ({
  langMenuOpened,
  setLangMenuOpened,
  setLangImage,
  setLangValue,
  langImage,
  langvalue,
}: Props) => {
  const { t } = useTranslation();
  const medplum = useMedplum();
  const { classes, cx } = useStyles();
  const profile = useAuthMeStore((s) => s.profile);
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  const handleLanguage = async (lang: any) => {
    if (lang === 'en') {
      setLangImage('../../img/dashboard-assets/us-Icon.svg');
      setLangValue('EN');
    } else if (lang === 'de') {
      setLangImage('../../img/dashboard-assets/german.png');
      setLangValue('DE');
    }

    if (isLoggedIn && profile && profile?.resourceType === 'Patient') {
      medplum.updateResource({
        ...profile,
        id: profile?.id,
        resourceType: 'Patient',
        communication: [
          {
            language: {
              coding: [{ code: lang }],
            },
          },
        ],
      });
    }

    i18n.changeLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };

  return (
    <Menu
      width={260}
      shadow="xl"
      position="bottom-end"
      transitionProps={{ transition: 'pop-top-right' }}
      opened={langMenuOpened}
      onClose={() => setLangMenuOpened(false)}
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(classes.user, { [classes.userActive]: langMenuOpened })}
          onClick={() => setLangMenuOpened(!langMenuOpened)}
          mr={8}
        >
          <Group spacing={7}>
            {/* <img src={langImage} style={{ width: '20px' }} /> */}
            {langImage}
            <Text size="sm">{langvalue}</Text>
            <IconChevronDown size={12} stroke={1.5} />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown sx={{ width: '6.25rem !important' }}>
        <Menu.Item
          icon={<img src="../../img/dashboard-assets/us-Icon.svg" style={{ width: '18px' }} />}
          onClick={() => handleLanguage('en')}
        >
          {t('common.english')}
        </Menu.Item>
        <Menu.Item
          icon={<img src="../../img/dashboard-assets/german.png" style={{ width: '20px' }} />}
          onClick={() => handleLanguage('de')}
        >
          {t('common.german')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default LanguageSwitchMenu;
