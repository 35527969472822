import React, { useState } from 'react';
import { Text, Modal, TextInput, Container, Button, Checkbox, Flex, Title, Alert, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useCreateUpdateGroupAndGoals } from '../../../queries/group/useCreateUpdateGroupAndGoals';
import { IconAlertCircle, IconFlame, IconFlower, IconMan, IconRun } from '@tabler/icons-react';
import { useGetGroupDetailAndGoals } from '../../../queries/group/useGetGroupDetailAndGoals';
import { Goal, Group } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';

interface addUserProps {
  open?: any;
  selectedGroup?: Group;
  closePopup?: any;
  type: 'create' | 'update';
  loadResults?: (options?: RequestInit) => void;
}
interface FormData {
  groupName: string;
  timeZone: string | undefined;
  activity: number;
  activityId: string | undefined;
  steps: number;
  stepsId: string | undefined;
  standUp: number;
  standUpId: string | undefined;
  mindful: number;
  mindfulId: string | undefined;
  // rings: string;
  isStepsChecked: boolean;
  isActivityChecked: boolean;
  isStandupChecked: boolean;
  isMindfulChecked: boolean;
}

export enum GoalCodes {
  ACTIVITY = '41981-2',
  STEPS = '55423-8',
  STAND_UP = '25230-4',
  MINDFUL = '56293-5',
}

export enum GoalRanges {
  ACTIVITY_MIN = 100,
  ACTIVITY_DEFAULT = 200,
  ACTIVITY_MAX = 300,
  STEPS_MIN = 1500,
  STEPS_DEFAULT = 8000,
  STEPS_MAX = 15000,
  STAND_UP_MIN = 5,
  STAND_UP_DEFAULT = 8,
  STAND_UP_MAX = 12,
  MINDFUL_MIN = 1,
  MINDFUL_DEFAULT = 3,
  MINDFUL_MAX = 8,
}

export const CreateGroup: React.FC<addUserProps> = ({
  open,
  selectedGroup,
  closePopup,
  type: formType,
  loadResults,
}) => {
  const { t } = useTranslation();
  const orgId = localStorage.getItem('orgId');

  const [formData, setFormData] = useState<FormData>({
    groupName: '',
    timeZone: undefined,
    activity: GoalRanges.ACTIVITY_DEFAULT,
    activityId: undefined,
    steps: GoalRanges.STEPS_DEFAULT,
    stepsId: undefined,
    standUp: GoalRanges.STAND_UP_DEFAULT,
    standUpId: undefined,
    mindful: GoalRanges.MINDFUL_DEFAULT,
    mindfulId: undefined,
    // rings: '4',
    isStepsChecked: true,
    isActivityChecked: true,
    isStandupChecked: true,
    isMindfulChecked: true,
  });

  const allTimezones: string[] = Intl.supportedValuesOf('timeZone');

  useGetGroupDetailAndGoals(selectedGroup?.id, {
    enabled: !!selectedGroup?.id,
    retry: false,
    onSuccess: (data: { group: Group; goals: Goal[] }) => {
      const { group, goals } = data;
      const findActivity = goals?.find(
        (goal: Goal) => goal?.target?.[0]?.measure?.coding?.[0]?.code === GoalCodes.ACTIVITY
      );

      const findSteps = goals?.find((goal: Goal) => goal?.target?.[0]?.measure?.coding?.[0]?.code === GoalCodes.STEPS);
      const findStandUp = goals?.find(
        (goal: Goal) => goal?.target?.[0]?.measure?.coding?.[0]?.code === GoalCodes.STAND_UP
      );
      const findMindful = goals?.find(
        (goal: Goal) => goal?.target?.[0]?.measure?.coding?.[0]?.code === GoalCodes.MINDFUL
      );

      setFormData({
        ...formData,
        groupName: group?.name ?? '',
        timeZone: group?.identifier?.find((i) => i?.system === 'https://www.iana.org/time-zones')?.value ?? '',
        activity: Number(findActivity?.target?.[0]?.detailQuantity?.value),
        activityId: findActivity?.id,
        steps: Number(findSteps?.target?.[0]?.detailQuantity?.value),
        stepsId: findSteps?.id,
        standUp: Number(findStandUp?.target?.[0]?.detailQuantity?.value),
        standUpId: findStandUp?.id,
        mindful: Number(findMindful?.target?.[0]?.detailQuantity?.value),
        mindfulId: findMindful?.id,
        // rings: group?.extension?.find((ext: Extension) => ext?.url === 'http://week.target')?.valueString ?? '',
        isActivityChecked: findActivity?.lifecycleStatus === 'active' ? true : false,
        isStepsChecked: true,
        isStandupChecked: findStandUp?.lifecycleStatus === 'active' ? true : false,
        isMindfulChecked: findMindful?.lifecycleStatus === 'active' ? true : false,
      });
    },
  });

  const { mutateAsync: createUpdateGroupAndGoals, isLoading: isSaveLoading } = useCreateUpdateGroupAndGoals({
    onSuccess: async () => {
      loadResults?.();
      showNotification({ message: t('common.success'), color: 'green' });
    },
    onError: () => {
      showNotification({ message: t('common.error'), color: 'red' });
    },
    onSettled: () => {
      closePopup();
    },
  });

  const submitGroup = async () => {
    const checkBoxValuesArr = [
      formData.isStepsChecked,
      formData.isActivityChecked,
      formData.isStandupChecked,
      formData.isMindfulChecked,
    ];
    const isCheckedArr = checkBoxValuesArr.filter((val) => val === true);
    if (isCheckedArr.length < 3) {
      showNotification({ message: t('teams.min-3-rings'), color: 'red' });
      return;
    } else if (formData.steps < GoalRanges.STEPS_MIN || formData.steps > GoalRanges.STEPS_MAX) {
      showNotification({
        message: `${t('teams.steps-should-between')} ${GoalRanges.STEPS_MIN} ${t('common.and')} ${
          GoalRanges.STEPS_MAX
        } ${t('teams.steps')}`,
        color: 'red',
      });
      return;
    } else if (formData.activity < GoalRanges.ACTIVITY_MIN || formData.activity > GoalRanges.ACTIVITY_MAX) {
      showNotification({
        message: `${t('teams.activity-should-between')} ${GoalRanges.ACTIVITY_MIN} ${t('common.and')} ${
          GoalRanges.ACTIVITY_MAX
        } ${t('teams.calories')}`,
        color: 'red',
      });
      return;
    } else if (formData.mindful < GoalRanges.MINDFUL_MIN || formData.mindful > GoalRanges.MINDFUL_MAX) {
      showNotification({
        message: `${t('teams.mindful-should-between')} ${GoalRanges.MINDFUL_MIN} ${t('common.and')}  ${
          GoalRanges.MINDFUL_MAX
        } ${t('teams.sessions')}`,
        color: 'red',
      });
      return;
    } else if (formData.standUp < GoalRanges.STAND_UP_MIN || formData.standUp > GoalRanges.STAND_UP_MAX) {
      showNotification({
        message: `${t('teams.standup-should-between')} ${GoalRanges.STAND_UP_MIN} ${t('common.and')} ${
          GoalRanges.STAND_UP_MAX
        } ${t('teams.hours')}`,
        color: 'red',
      });
      return;
    } else {
      await createUpdateGroupAndGoals({
        type: formType,
        groupBody: {
          name: formData.groupName,
          // weekTarget: formData.rings,
          organizationId: orgId as string,
          timeZone: formData.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        goals: [
          {
            isChecked: formData.isActivityChecked,
            id: formData.activityId,
            type: 'Activity',
            unit: 'calories',
            code: GoalCodes.ACTIVITY,
            groupName: formData.groupName,
            target: formData.activity,
          },
          {
            isChecked: formData.isStepsChecked,
            id: formData.stepsId,
            type: 'Steps',
            unit: 'steps',
            code: GoalCodes.STEPS,
            groupName: formData.groupName,
            target: formData.steps,
          },
          {
            isChecked: formData.isStandupChecked,
            id: formData.standUpId,
            type: 'Stand Up',
            unit: 'hours',
            code: GoalCodes.STAND_UP,
            groupName: formData.groupName,
            target: formData.standUp,
          },
          {
            isChecked: formData.isMindfulChecked,
            id: formData.mindfulId,
            type: 'Mindful',
            unit: 'sessions',
            code: GoalCodes.MINDFUL,
            groupName: formData.groupName,
            target: formData.mindful,
          },
        ],
        groupId: selectedGroup?.id ?? undefined,
        members: selectedGroup?.member ?? [],
        groupCode: selectedGroup?.code ?? undefined,
      });
    }
  };

  const handleCheckboxChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Modal
      opened={open}
      onClose={closePopup}
      size="md"
      centered
      title={formType === 'create' ? `${t('teams.create-new-team')}` : `${t('teams.update-team')}`}
    >
      <form>
        <Container mt={12}>
          <TextInput
            placeholder={t('teams.team-name')}
            label={t('teams.team-name')}
            mb="md"
            name="groupName"
            onChange={(e) => setFormData({ ...formData, groupName: e.target.value })}
            value={formData?.groupName}
            readOnly={formData?.groupName === 'Default' ? true : false}
          />
          <Select
            label="Team time zone"
            placeholder="Select a time zone"
            value={formData?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
            data={allTimezones.map((zone) => ({ value: zone, label: zone }))}
            mb="md"
            onChange={(value: string) => setFormData({ ...formData, timeZone: value })}
          />
          <Title order={3}>{t('teams.rings')}</Title>
          <Text size="sm" mb={20} style={{ color: '#606E9F' }}>
            {t('teams.rings-description')}
          </Text>
          {/* STEPS */}
          <Flex direction="column" mb={20}>
            <TextInput
              label={`${t('teams.steps')} • (${GoalRanges.STEPS_MIN} - ${GoalRanges.STEPS_MAX}) ${t('teams.steps')}`}
              icon={<IconRun color="#83E802" />}
              width={'100%'}
              type="number"
              name="steps"
              min={1500}
              max={15000}
              placeholder={`${t('teams.steps')} • (${GoalRanges.STEPS_MIN} - ${GoalRanges.STEPS_MAX}) ${t(
                'teams.steps'
              )}`}
              value={formData?.steps}
              onChange={(e) => setFormData({ ...formData, steps: Number(e.target.value) })}
            />
          </Flex>
          {/* ACTIVITY */}
          <Flex direction="column" mb={20}>
            <Checkbox
              name="isActivityChecked"
              mb={8}
              label={`${t('teams.activity')} • (${GoalRanges.ACTIVITY_MIN} - ${GoalRanges.ACTIVITY_MAX}) ${t(
                'teams.calories'
              )}`}
              checked={formData.isActivityChecked}
              onChange={(e) => handleCheckboxChange(e)}
            />
            <TextInput
              icon={<IconFlame color="#FF0909" />}
              width={'100%'}
              type="number"
              name="activity"
              min={100}
              max={300}
              placeholder={`${t('teams.activity')} • (${GoalRanges.ACTIVITY_MIN} - ${GoalRanges.ACTIVITY_MAX}) ${t(
                'teams.calories'
              )}`}
              disabled={!formData.isActivityChecked}
              value={formData?.activity}
              onChange={(e) => setFormData({ ...formData, activity: Number(e.target.value) })}
            />
          </Flex>
          {/* MINDFUL */}
          <Flex direction="column" mb={20}>
            <Checkbox
              name="isMindfulChecked"
              mb={8}
              label={`Mindfulness • (${GoalRanges.MINDFUL_MIN} - ${GoalRanges.MINDFUL_MAX}) ${t('teams.sessions')}`}
              checked={formData.isMindfulChecked}
              onChange={(e) => handleCheckboxChange(e)}
            />
            <TextInput
              icon={<IconFlower color="#6B66FF" />}
              width={'100%'}
              type="number"
              name="mindful"
              min={1}
              max={8}
              placeholder={`Mindfulness • (${GoalRanges.MINDFUL_MIN} - ${GoalRanges.MINDFUL_MAX}) ${t(
                'teams.sessions'
              )}`}
              disabled={!formData.isMindfulChecked}
              value={formData?.mindful}
              onChange={(e) => setFormData({ ...formData, mindful: Number(e.target.value) })}
            />
          </Flex>
          {/* STAND UP */}
          <Flex direction="column" mb={20}>
            <Checkbox
              name="isStandupChecked"
              mb={8}
              label={`${t('teams.stand-up')} • (${GoalRanges.STAND_UP_MIN} - ${GoalRanges.STAND_UP_MAX}) ${t(
                'teams.hours'
              )}`}
              checked={formData.isStandupChecked}
              onChange={(e) => handleCheckboxChange(e)}
            />
            <TextInput
              icon={<IconMan color="#01EBD4" />}
              width={'100%'}
              type="number"
              name="standUp"
              min={5}
              max={12}
              placeholder={`${t('teams.stand-up')} • (${GoalRanges.STAND_UP_MIN} - ${GoalRanges.STAND_UP_MAX}) ${t(
                'teams.hours'
              )}`}
              disabled={!formData.isStandupChecked}
              value={formData?.standUp}
              onChange={(e) => setFormData({ ...formData, standUp: Number(e.target.value) })}
            />
          </Flex>

          <Alert icon={<IconAlertCircle size="1rem" />} title={`${t('teams.please-note')}:`}>
            {t('teams.create-team-alert')}
          </Alert>

          {/* <TextInput
                type="number"
                min={1}
                max={7}
                label={t('ringClose')}
                name="rings"
                mt="xl"
                onChange={(e) => setFormData({ ...formData, rings: e.target.value })}
                value={formData?.rings}
              /> */}
        </Container>
        <Container sx={{ marginTop: '50px' }}>
          <Flex align="center" justify="flex-end">
            <Button
              variant="filled"
              ml={12}
              loading={isSaveLoading}
              disabled={!formData?.groupName}
              onClick={submitGroup}
            >
              {formType === 'create' ? t('common.create') : t('common.update')}
            </Button>
          </Flex>
        </Container>
      </form>
    </Modal>
  );
};
