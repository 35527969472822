import { Grid } from '@mantine/core';
import * as React from 'react';
import { OrganizationEdit } from './components/OrganizationEdit';

const OrganizationSettingsPage = () => {
  return (
    <Grid p={0}>
      <Grid.Col xs={12}>
        <OrganizationEdit />
      </Grid.Col>
    </Grid>
  );
};

export default OrganizationSettingsPage;
