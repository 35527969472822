import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../../config';

const keys = {
  all: [QueryKeys.GET_STRIPE_PRODUCTS] as const,
  byParams: () => [...keys.all],
};

export const useGetStripeProducts = (
  options?: UseQueryOptions<any | undefined, Error, any | undefined, readonly [...string[]]>
): UseQueryResult<any | undefined, Error> => {
  const medplum = useMedplum();
  const { getStripeProducts } = getConfig();

  return useQuery<any | undefined, Error, any | undefined, readonly [...string[]]>(
    keys.byParams(),
    async () => {
      const data = await medplum.executeBot(getStripeProducts, {});
      return data;
    },
    options
  );
};
