import { axiosServices1, axiosServices2 } from './utils/axios';

export const newPatient = async (login: any, projectId: any) => {
  try {
    const response = await axiosServices1.post(
      `auth/newpatient `,
      { login: login, projectId: projectId },
      {
        headers: {
          Accept: 'application/fhir+json',
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getInviteLink = async (medplum: any, patientId: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axiosServices2.get(`/get-invite-link?patientId=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendInviteEmail = async (medplum: any, emails: any, patientId: any, inviteLink: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axiosServices2.post(
      `/send-invite-email `,
      { emails: emails, patientId: patientId, inviteLink: inviteLink },
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const registerUser = async (token: string, email: string, projectId: string, organizationId?: string) => {
  try {
    const response = await axiosServices2.post(
      `/register-user`,
      { email: email, projectId: projectId, organizationId: organizationId },
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
