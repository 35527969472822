import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Flex,
  Loader,
  NativeSelect,
  Pagination,
  Paper,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { Container, useMedplum } from '@medplum/react';
import { IconPencil, IconPlug, IconPlugOff, IconPlus, IconRefresh, IconSearch } from '@tabler/icons-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useGetOrganizationList } from '../../queries/organization/useGetOrganizationList';
import { QueryKeys } from '../../queries/query-keys';
import { useDeactivateOrganization } from '../../queries/organization/useDeactivateOrganization';
import { useActivateOrganization } from '../../queries/organization/useActivateOrganization';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { CreateNewOrganizationModal } from './components/CreateNewOrganizationModal';
import _ from 'lodash';

const CustomersPage = () => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const medplum = useMedplum();
  const isProjectAdmin = medplum.isProjectAdmin();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [createNewOrgModalVisible, setCreateNewOrgModalVisible] = useState(false);

  useEffect(() => {
    if (!isProjectAdmin) {
      navigate('/users');
    }
  }, []);

  const { data: customersData, isLoading } = useGetOrganizationList(page, count, debouncedSearchText, {
    refetchOnWindowFocus: true,
  });

  const refreshResults = useCallback(async () => await queryClient.resetQueries(QueryKeys.GET_ORGANIZATION_LIST), []);

  const { mutateAsync: deactivateOrg, isLoading: isDeactivateOrgLoading } = useDeactivateOrganization({
    onSuccess: async () => {
      refreshResults().then(() => {
        showNotification({ message: t('customers.org-deactivated'), color: 'green' });
      });
    },
    onError: () => {
      showNotification({ message: t('customers.deactivate-org-error'), color: 'red' });
    },
  });

  const handleDeactivateOrganization = async (organizationId: string, orgName: string) => {
    const confirmation = confirm(`${t('customers.are-you-sure-deactivate')} ${orgName} ${t('common.organization')}?`);
    if (confirmation) {
      await deactivateOrg({ organizationId });
    }
  };

  const { mutateAsync: activateOrg, isLoading: isReactivateOrgLoading } = useActivateOrganization({
    onSuccess: async () => {
      refreshResults().then(() => {
        showNotification({ message: t('customers.org-activated'), color: 'green' });
      });
    },
    onError: () => {
      showNotification({ message: t('customers.activate-org-error'), color: 'red' });
    },
  });

  const handleActivateOrganization = async (organizationId: string, orgName: string) => {
    const confirmation = confirm(`${t('customers.are-you-sure-activate')} ${orgName} ${t('common.organization')}?`);
    if (confirmation) {
      await activateOrg({ organizationId });
    }
  };

  const debouncedHandleInputChange = _.debounce((value) => {
    setDebouncedSearchText(value);
  }, 1000);

  const handleSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedHandleInputChange(value);
  };

  const rows = customersData?.data?.map((r: any) => (
    <tr key={r?.id}>
      <td style={{ minWidth: 200 }}>{r?.name}</td>
      <td style={{ minWidth: 140 }}>{moment(r?.meta?.lastUpdated).format('DD MMM YYYY, HH:mm')}</td>
      <td style={{ minWidth: 200 }}>{r?.contact?.[0]?.telecom?.find((t: any) => t?.system === 'email')?.value}</td>
      <td>
        {r?.isPending ? (
          <Badge variant="outline" color="yellow">
            {t('common.invited')}
          </Badge>
        ) : !r?.isPending && r?.active ? (
          <Badge variant="outline" color="green">
            {t('common.active')}
          </Badge>
        ) : (
          <Badge variant="outline" color="red">
            {t('common.inactive')}
          </Badge>
        )}
      </td>
      <td>
        {r?.patientCount}/{r?.stripeProduct?.metadata?.licensesAmount ?? '--'}
      </td>
      <td
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip label={t('customers.edit-organization')}>
          <ActionIcon onClick={() => navigate(`/edit-organization/${r?.id}/${r?.stripeProduct?.id}`)}>
            <IconPencil color={theme.colors.blue[8]} size={18} />
          </ActionIcon>
        </Tooltip>
        {r?.active ? (
          <Tooltip label={t('customers.deactivate-organization')}>
            <ActionIcon onClick={() => handleDeactivateOrganization(r?.id as string, r?.name as string)}>
              <IconPlugOff color={theme.colors.red[8]} size={18} />
            </ActionIcon>
          </Tooltip>
        ) : (
          <Tooltip label={t('customers.activate-organization')}>
            <ActionIcon onClick={() => handleActivateOrganization(r?.id as string, r?.name as string)}>
              <IconPlug color={theme.colors.green[8]} size={18} />
            </ActionIcon>
          </Tooltip>
        )}
      </td>
    </tr>
  ));

  return (
    <Paper shadow="xs" m="md" className="patient-list">
      <div className={classes.root} data-testid="search-control">
        <Flex px={12} direction="column" sx={{ borderBottom: '1px solid #f3f3f3' }}>
          <Flex py={12} direction="column">
            <Title fw="bold" order={2}>
              {t('customers.customers')}
            </Title>
            {/* <Text color="grey" size="sm">
              {t('teams.teams-subheadline')}
            </Text> */}
          </Flex>
          <Flex
            py={12}
            sx={{ borderTop: '1px solid #f3f3f3' }}
            w="100%"
            direction="row"
            align="center"
            justify="space-between"
          >
            <Flex direction="row" align="center">
              <TextInput
                placeholder={`${t('common.search')}...`}
                icon={<IconSearch size={20} />}
                value={searchText}
                mr={12}
                onChange={handleSearchTextChange}
              />
              <Button
                onClick={() => setCreateNewOrgModalVisible(true)}
                size="sm"
                mr={12}
                leftIcon={<IconPlus size={14} />}
              >
                {t('common.create')}
              </Button>
            </Flex>
            <Flex direction="row" align="center">
              {customersData && (
                <Text size="xs" color="dimmed">
                  {page * count - (count - 1)} -{' '}
                  {page * count > customersData?.total ? customersData?.total : page * count} of{' '}
                  {`${customersData?.total}`}
                </Text>
              )}
              <ActionIcon onClick={refreshResults} title="Refresh">
                <IconRefresh size="1.125rem" />
              </ActionIcon>
            </Flex>
          </Flex>
        </Flex>
        {isLoading || isDeactivateOrgLoading || isReactivateOrgLoading ? (
          <Flex align="center" justify="center" direction="column" w="100%" p={20}>
            <Loader mb={12} />
            <Text color={theme.primaryColor} fz={12}>
              {t('customers.this-might-take-a-while')}...
            </Text>
          </Flex>
        ) : (
          <ScrollArea>
            <Table highlightOnHover withColumnBorders striped horizontalSpacing="sm" verticalSpacing="sm">
              <thead>
                <tr>
                  <th>{t('table.name')}</th>
                  <th>{t('table._lastUpdated')}</th>
                  <th>{t('table.admin-email')}</th>
                  <th>{t('table.status')}</th>
                  <th>{t('table.members-licenses')}</th>
                  <th style={{ textAlign: 'end' }}>{t('table.action')}</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        )}
        {customersData?.total === 0 && (
          <Container>
            <Center style={{ height: 150 }}>
              <Text size="xl" color="dimmed">
                {t('common.no-results')}
              </Text>
            </Center>
          </Container>
        )}
        {customersData?.total !== undefined && (
          <Flex direction="row" align="center" justify="space-between" m="md" p="md">
            <Flex />
            <Pagination value={page} onChange={setPage} total={Math.ceil(customersData?.total / count)} />
            <Flex direction="row" align="center" sx={{ alignSelf: 'flex-end' }}>
              <Text size="sm" color="dimmed">
                {t('table.rows-per-page')}:
              </Text>
              <NativeSelect
                size="sm"
                ml={12}
                value={count}
                data={['5', '10', '20', '50', '100']}
                onChange={(e) => setCount(Number(e.target.value))}
              />
            </Flex>
          </Flex>
        )}
      </div>

      <CreateNewOrganizationModal
        title={t('customers.create-new-organization')}
        resourceType="Organization"
        visible={createNewOrgModalVisible}
        fetchOrgList={refreshResults}
        onCancel={() => setCreateNewOrgModalVisible(false)}
      />
    </Paper>
  );
};

export default CustomersPage;

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  table: {},

  tr: {
    height: '55px',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));
