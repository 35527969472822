import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { Patient } from '@medplum/fhirtypes';

export interface IUpdatePatientProps {
  body: Patient;
}

export const useUpdatePatient = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();

  return useMutation(async ({ body }: IUpdatePatientProps) => {
    return medplum.updateResource({
      ...body,
    });
  }, options);
};
