import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';
import { Organization } from '@medplum/fhirtypes';

const keys = {
  all: [QueryKeys.GET_ORGANIZATION_BY_ID] as const,
  byParams: (orgId: string) => [...keys.all, orgId],
};

export const useGetOrganizationById = (
  orgId: string,
  options?: UseQueryOptions<
    Organization | undefined,
    Error,
    Organization | undefined,
    readonly [...(string | undefined)[]]
  >
): UseQueryResult<Organization | undefined, Error> => {
  const medplum = useMedplum();

  return useQuery<Organization | undefined, Error, Organization | undefined, readonly [...(string | undefined)[]]>(
    keys.byParams(orgId),
    async () => await medplum.readResource(`Organization`, orgId, { cache: 'no-cache' }),
    options
  );
};
