import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKeys } from '../query-keys';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../../config';

const keys = {
  all: [QueryKeys.GET_SUBSCRIPTION_BY_ID] as const,
  byParams: (subscriptionId: string) => [...keys.all, subscriptionId],
};

export const useGetStripeSubscriptionById = (
  subscriptionId: string,
  options?: UseQueryOptions<any | undefined, Error, any | undefined, readonly [...string[]]>
): UseQueryResult<any | undefined, Error> => {
  const medplum = useMedplum();
  const { getSubscriptionById } = getConfig();

  return useQuery<any | undefined, Error, any | undefined, readonly [...string[]]>(
    keys.byParams(subscriptionId),
    async () => {
      const data = await medplum.executeBot(getSubscriptionById, {
        id: subscriptionId,
      });
      return data;
    },
    options
  );
};
