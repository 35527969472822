import React from 'react';
import { Document } from '../../../../react/src/Document/Document';
import { useMedplum } from '@medplum/react';
import { NewUserForm } from './NewUserForm';
import { useTranslation } from 'react-i18next';

export interface RegisterFormProps {
  readonly type: 'patient' | 'project';
  readonly projectId?: string;
  readonly clientId?: string;
  readonly googleClientId?: string;
  readonly recaptchaSiteKey?: string;
  readonly children?: React.ReactNode;
  readonly onSuccess: () => void;
}

export function RegisterForm(props: RegisterFormProps): JSX.Element {
  const { projectId, clientId, recaptchaSiteKey, onSuccess } = props;
  const { t } = useTranslation();
  const medplum = useMedplum();

  function handleAuthResponse(response: any): void {
    if (response.code) {
      medplum
        .processCode(response.code)
        .then(() => onSuccess())
        .catch((err: any) => console.error(`${t(err.message)}`));
    }
  }

  return (
    <Document width={450}>
      <NewUserForm
        projectId={projectId as string}
        clientId={clientId}
        // googleClientId={googleClientId}
        recaptchaSiteKey={recaptchaSiteKey}
        handleAuthResponse={handleAuthResponse}
      >
        {props.children}
      </NewUserForm>
    </Document>
  );
}
