import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { Practitioner } from '@medplum/fhirtypes';

export interface IUpdatePractitionerProps {
  body: Practitioner;
}

export const useUpdatePractitioner = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();

  return useMutation(async ({ body }: IUpdatePractitionerProps) => {
    return medplum.updateResource({
      ...body,
    });
  }, options);
};
