import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';
import { Bundle, Group } from '@medplum/fhirtypes';

const keys = {
  all: [QueryKeys.GET_TEAMS] as const,
  byParams: (page: number, count: number, searchText: string) => [...keys.all, page, count, searchText],
};

export const useGetTeamsTable = (
  page: number,
  count: number,
  searchText: string,
  options?: UseQueryOptions<any | undefined, Error, any | undefined, readonly [...(string | number | undefined)[]]>
): UseQueryResult<any | undefined, Error> => {
  const medplum = useMedplum();

  return useQuery<any | undefined, Error, any | undefined, readonly [...(string | number | undefined)[]]>(
    keys.byParams(page, count, searchText),
    async (): Promise<{ total: number | undefined; teams: Group[] | undefined } | undefined> =>
      medplum
        .executeBatch(
          {
            resourceType: 'Bundle',
            type: 'batch',
            entry: [
              {
                request: {
                  method: 'GET',
                  url: `Group?_summary=count`,
                },
              },
              {
                request: {
                  method: 'GET',
                  url: `Group?_count=${count}&_offset=${
                    page === 1 ? 0 : count * (page - 1)
                  }&name:contains=${searchText}`,
                },
              },
            ],
          },
          {
            cache: 'no-cache',
          }
        )
        .then((resp) => {
          const totalResource = resp?.entry?.[0]?.resource as Bundle;
          const total = totalResource?.total;

          const teamsResource = resp?.entry?.[1]?.resource as Bundle;
          const teams = teamsResource?.entry?.map((e) => e?.resource as Group);

          return {
            teams,
            total,
          };
        }),
    options
  );
};
