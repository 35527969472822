import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';
import { Account } from '@medplum/fhirtypes';

const keys = {
  all: [QueryKeys.GET_ACCOUNT] as const,
  byParams: (organizationId: string) => [...keys.all, organizationId],
};

export const useGetAccount = (
  organizationId: string,
  options?: UseQueryOptions<Account | undefined, Error, Account | undefined, readonly [...(string | undefined)[]]>
): UseQueryResult<Account | undefined, Error> => {
  const medplum = useMedplum();

  return useQuery<Account | undefined, Error, Account | undefined, readonly [...(string | undefined)[]]>(
    keys.byParams(organizationId),
    async () => await medplum.searchOne('Account', `subject=Organization/${organizationId}`, { cache: 'reload' }),
    options
  );
};
