import { Flex, Image } from '@mantine/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';

interface Props {
  appStoreUrl: string;
  googlePlayStoreUrl: string;
}

export const DownloadApp: React.FC<Props> = ({ appStoreUrl, googlePlayStoreUrl }) => {
  return (
    <Flex
      w={'100%'}
      h={'100%'}
      direction={{ base: 'column', sm: 'row' }}
      align={{ md: 'center', sm: 'flex-start' }}
      justify="start"
      py={{ xs: 0, md: 12 }}
    >
      <Flex direction="column" mr={{ xs: 0, md: 100 }}>
        <Link style={{ padding: '10px 0' }} target="_blank" to={googlePlayStoreUrl}>
          <Image
            fit="contain"
            width={160}
            mr={{ xs: 0, sm: 12 }}
            sx={{ flexShrink: 0, cursor: 'pointer', marginBottom: 40 }}
            src="/img/play-store.png"
            alt="google-play"
          />
        </Link>
        <QRCode size={172} value={googlePlayStoreUrl} viewBox={`0 0 172 172`} />
      </Flex>
      <Flex direction="column" mt={{ xs: 20, md: 0 }}>
        <Link style={{ padding: '10px 0' }} target="_blank" to={appStoreUrl}>
          <Image
            fit="contain"
            width={160}
            sx={{ flexShrink: 0, cursor: 'pointer', marginBottom: 40 }}
            src="/img/app-store.png"
            alt="app-store"
          />
        </Link>

        <QRCode size={172} value={appStoreUrl} viewBox={`0 0 172 172`} />
      </Flex>
    </Flex>
  );
};
