import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';
import { Group } from '@medplum/fhirtypes';

const keys = {
  all: [QueryKeys.GET_GROUP_BY_PATIENT_ID] as const,
  byParams: (patientId: string) => [...keys.all, patientId],
};

export const useGetGroupByPatientId = (
  patientId: string,
  options?: UseQueryOptions<Group | undefined, Error, Group | undefined, readonly [...(string | undefined)[]]>
): UseQueryResult<Group | undefined, Error> => {
  const medplum = useMedplum();

  return useQuery<Group | undefined, Error, Group | undefined, readonly [...(string | undefined)[]]>(
    keys.byParams(patientId),
    async () => await medplum.searchOne('Group', `member=Patient/${patientId}`),
    options
  );
};
