import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { Account, Organization } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../config';

export interface IUpgradeStripeSubscriptionBody {
  isUpgradePlanOpen: boolean;
  currentSubsItemId: string;
  productId: string;
  priceId: string;
  subscriptionId: string;
}
export interface IUpdateOrganizationProps {
  currentAccount: Account;
  body: Organization;
  upgradePlanBody: IUpgradeStripeSubscriptionBody;
}

export const useUpdateOrganization = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const { t } = useTranslation();
  const { upgradeStripePlan } = getConfig();

  return useMutation(async ({ body, upgradePlanBody, currentAccount }: IUpdateOrganizationProps) => {
    return medplum
      .updateResource({
        ...body,
      })
      .then(async () => {
        if (upgradePlanBody.isUpgradePlanOpen) {
          try {
            await medplum
              .executeBot(upgradeStripePlan, {
                currentSubsItemId: upgradePlanBody.currentSubsItemId,
                priceId: upgradePlanBody.priceId,
                subscriptionId: upgradePlanBody.subscriptionId,
              })
              .then(async () => {
                showNotification({ color: 'green', message: t('customers.plan-upgraded-success') });
                const filterAccountIdentifierWithProductId = currentAccount.identifier?.filter(
                  (c) => c?.system !== 'productId'
                );
                const accountBody = {
                  ...currentAccount,
                  identifier: [
                    ...(filterAccountIdentifierWithProductId ?? []),
                    {
                      system: 'productId',
                      value: upgradePlanBody.productId,
                    },
                  ],
                };
                await medplum.updateResource({
                  ...accountBody,
                });
              });
          } catch (error) {
            console.error(error);
          }
        }
      });
  }, options);
};
