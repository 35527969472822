/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Anchor, Button, Center, Divider, Group, PasswordInput, Stack, TextInput } from '@mantine/core';
import {
  BaseLoginRequest,
  GoogleCredentialResponse,
  GoogleLoginRequest,
  LoginAuthenticationResponse,
  normalizeOperationOutcome,
} from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import React, { useCallback, useState } from 'react';
import { Form } from '../../../../react/src/Form/Form';
import { getGoogleClientId, GoogleButton } from '../../../../react/src/GoogleButton/GoogleButton';
import { useMedplum } from '@medplum/react';
import { OperationOutcomeAlert } from '../../../../react/src/OperationOutcomeAlert/OperationOutcomeAlert';
import { getErrorsForInput, getIssuesForExpression } from '../../../../react/src/utils/outcomes';
import { newPatient, registerUser } from '../../fhirApi';
import { useNavigate } from 'react-router-dom';
import { getConfig } from '../../config';
import { useTranslation } from 'react-i18next';

export interface AuthenticationFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onForgotPassword?: () => void;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly children?: React.ReactNode;
  readonly step: 'email' | 'password';
  readonly setStep: (val: 'email' | 'password') => void;
}

export function AuthenticationForm(props: AuthenticationFormProps): JSX.Element {
  const [email, setEmail] = useState<string>();

  if (props.step === 'email') {
    return <EmailForm setFormStep={props.setStep} setEmail={setEmail} {...props} />;
  } else if (email) {
    return <PasswordForm email={email} {...props} />;
  } else {
    return <></>;
  }
}

export interface EmailFormProps extends BaseLoginRequest {
  readonly disableEmailAuth?: boolean;
  readonly disableGoogleAuth?: boolean;
  readonly onRegister?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly setEmail: (email: string) => void;
  readonly children?: React.ReactNode;
  readonly setFormStep: (val: 'email' | 'password') => void;
}

export function EmailForm(props: EmailFormProps): JSX.Element {
  const { t } = useTranslation();
  const { setEmail, onRegister, handleAuthResponse, children, disableEmailAuth, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const googleClientId = !props.disableGoogleAuth && getGoogleClientId(props.googleClientId);
  const navigate = useNavigate();
  const isExternalAuth = useCallback(
    async (authMethod: any): Promise<boolean> => {
      if (!authMethod.authorizeUrl) {
        return false;
      }

      const state = JSON.stringify({
        ...(await medplum.ensureCodeChallenge(baseLoginRequest)),
        domain: authMethod.domain,
      });
      const url = new URL(authMethod.authorizeUrl);
      url.searchParams.set('state', state);
      window.location.assign(url.toString());
      return true;
    },
    [medplum, baseLoginRequest]
  );

  const handleSubmit = useCallback(
    async (formData: Record<string, string>) => {
      const authMethod = await medplum.post('auth/method', { email: formData.email });
      if (!(await isExternalAuth(authMethod))) {
        setEmail(formData.email);
        props.setFormStep('password');
      }
    },
    [medplum, isExternalAuth, setEmail]
  );

  async function registerNewUser(accessToken: string, login: string, code: string): Promise<void> {
    const projectId = import.meta.env.MEDPLUM_PROJECT_ID;
    await registerUser(accessToken, login, projectId)
      .then(() => {
        localStorage.setItem('isLoggedIn', 'true');
        window.location.reload();
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  }

  const handleGoogleCredential = useCallback(
    async (response: GoogleCredentialResponse) => {
      const authResponse = await medplum.startGoogleLogin({
        ...baseLoginRequest,
        googleCredential: response.credential,
        createUser: true,
        projectId: `${import.meta.env.MEDPLUM_PROJECT_ID}`,
      } as GoogleLoginRequest);
      if (authResponse.memberships?.length == 0) {
        const patientResponse = await newPatient(authResponse.login, `${import.meta.env.MEDPLUM_PROJECT_ID}`);
        await medplum.processCode(patientResponse?.data.code);
        localStorage.setItem('isLoggedIn', 'true');
        const logins = localStorage.getItem('activeLogin');
        if (logins) {
          const accessToken = JSON.parse(logins).accessToken;
          registerNewUser(accessToken, patientResponse?.data.login, patientResponse?.data.code);
          navigate('/');
        }
      } else {
        if (!(await isExternalAuth(authResponse))) {
          handleAuthResponse(authResponse);
        }
      }
    },
    [medplum, baseLoginRequest, isExternalAuth, handleAuthResponse]
  );

  return (
    <Form style={{ maxWidth: 400 }} onSubmit={handleSubmit}>
      <Center sx={{ flexDirection: 'column' }}>{children}</Center>
      {googleClientId && (
        <>
          <Group position="center" p="xl" style={{ height: 70 }}>
            <GoogleButton googleClientId={googleClientId} handleGoogleCredential={handleGoogleCredential} />
          </Group>
          {!disableEmailAuth && <Divider label="or" labelPosition="center" my="lg" />}
        </>
      )}
      {!disableEmailAuth && (
        <TextInput
          name="email"
          type="email"
          label={t('common.email')}
          placeholder="name@domain.com"
          required={true}
          autoFocus={true}
        />
      )}
      <Group position="apart" mt="xl" spacing={0} noWrap>
        <div>
          {onRegister && (
            <Anchor component="button" type="button" color="dimmed" onClick={onRegister} size="xs">
              {t('common.register')}
            </Anchor>
          )}
        </div>
        {!disableEmailAuth && <Button type="submit">{t('common.next')}</Button>}
      </Group>
    </Form>
  );
}

export interface PasswordFormProps extends BaseLoginRequest {
  readonly email: string;
  readonly onForgotPassword?: () => void;
  readonly handleAuthResponse: (response: LoginAuthenticationResponse) => void;
  readonly children?: React.ReactNode;
}

export function PasswordForm(props: PasswordFormProps): JSX.Element {
  const { t } = useTranslation();
  const { onForgotPassword, handleAuthResponse, children, ...baseLoginRequest } = props;
  const medplum = useMedplum();
  const config = getConfig();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const issues = getIssuesForExpression(outcome, undefined);

  const handleSubmit = useCallback(
    (formData: Record<string, string>) => {
      medplum
        .startLogin({
          ...baseLoginRequest,
          password: formData.password,
          remember: formData.remember === 'on',
          projectId: formData.projectId,
        })
        .then(handleAuthResponse)
        .catch((err) => setOutcome(normalizeOperationOutcome(err)));
    },
    [medplum, baseLoginRequest, handleAuthResponse]
  );
  return (
    <Form style={{ maxWidth: 400 }} onSubmit={handleSubmit}>
      <Center sx={{ flexDirection: 'column' }}>{children}</Center>
      <OperationOutcomeAlert issues={issues} />
      <Stack spacing="xl">
        <PasswordInput
          name="password"
          label={t('common.password')}
          autoComplete="off"
          required={true}
          autoFocus={true}
          error={t(`${outcome ? getErrorsForInput(outcome, 'password') : ''}`)}
        />
      </Stack>
      <input type="hidden" value={config?.projectId} name="projectId" />
      <Group position="apart" mt="xl" spacing={0} noWrap>
        {onForgotPassword && (
          <Anchor component="button" type="button" color="dimmed" onClick={onForgotPassword} size="xs">
            {t('auth.forgot-password')}
          </Anchor>
        )}
        {/* <Checkbox id="remember" name="remember" label="Remember me" size="xs" sx={{ lineHeight: 1 }} /> */}
        <Button type="submit">{t('auth.sign-in')}</Button>
      </Group>
    </Form>
  );
}
