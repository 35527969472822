import { Button, Flex, Modal, Text } from '@mantine/core';
import { MEDPLUM_VERSION, ProfileResource } from '@medplum/core';
import { AppShell, Loading, useMedplum } from '@medplum/react';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import './App.css';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAuthMeStore } from './store/useAuthMeStore';
import { AccessPolicyResource, Patient, ProjectMembership, UserConfiguration } from '@medplum/fhirtypes';
import LanguageSwitchMenu from './components/LanguageSwitchMenu';
import { getConfig } from './config';
import i18next from 'i18next';
import { showNotification } from '@mantine/notifications';

export interface IAuthMeResponse {
  project: {
    resourceType: string;
    id: string;
    name: string;
    strictMode: boolean;
  };
  membership: ProjectMembership;
  profile: ProfileResource;
  config: UserConfiguration;
  accessPolicy: {
    resourceType: string;
    resource: AccessPolicyResource[];
    ipAccessRule: any[];
  };
  security: {
    mfaEnrolled: boolean;
    sessions: {
      id: string;
      lastUpdated: string;
      authMethod: string;
      remoteAddress: string;
      browser: string;
      os: string;
    }[];
  };
}

export function App(): JSX.Element {
  const medplum = useMedplum();
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const { t } = useTranslation();
  const queryClient = new QueryClient();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getInvoices, deactivateOrganization } = getConfig();

  const [langMenuOpened, setLangMenuOpened] = useState(false);
  const [isAuthMeReady, setIsAuthMeReady] = useState(false);
  const [isOrgDeactivated, setIsOrgDeactivated] = useState(false);

  const paymentStatus = useAuthMeStore((s) => s.paymentStatus);

  const setUserId = useAuthMeStore((s) => s.setUserId);
  const setOrgId = useAuthMeStore((s) => s.setOrgId);
  const setOrganization = useAuthMeStore((s) => s.setOrganization);
  const setFlag = useAuthMeStore((s) => s.setFlag);
  const setIsCompanyAdmin = useAuthMeStore((s) => s.setIsCompanyAdmin);
  const setPaymentStatus = useAuthMeStore((s) => s.setPaymentStatus);
  const setProfile = useAuthMeStore((s) => s.setProfile);

  const [langvalue, setLangValue] = useState('DE');
  const [langImage, setLangImage] = useState('../../img/dashboard-assets/german.png');

  useEffect(() => {
    const localLang = localStorage.getItem('selectedLanguage');
    if (localLang) {
      if (localLang === 'en') {
        setLangImage('../../img/dashboard-assets/us-Icon.svg');
        setLangValue('EN');
      } else if (localLang === 'de') {
        setLangImage('../../img/dashboard-assets/german.png');
        setLangValue('DE');
      }
      i18next.changeLanguage(localLang);
    }
  }, []);

  // Fetch AuthMe data
  const fetchAuthMe = useCallback(async () => {
    try {
      setIsAuthMeReady(true);
      const data = await medplum.get('auth/me');
      if (!data) {
        setIsAuthMeReady(false);
        throw new Error('Auth me data not found');
      }
      const authMeData = data as IAuthMeResponse;

      const { membership, profile } = authMeData;

      // Check membership
      if (!membership) {
        setIsAuthMeReady(false);
        throw new Error('Membership data not found');
      }

      // Check if user is project admin
      if (membership) {
        setUserId(membership?.user?.reference?.split('/')?.[1]);

        if (profile) {
          setProfile(profile);
          setIsAuthMeReady(false);
        }
      }

      // Check profile
      if (!profile) {
        setIsAuthMeReady(false);
        throw new Error('Profile data not found');
      }

      if (profile) {
        setProfile(profile);
      }

      const organizationId = (profile as Patient)?.managingOrganization?.reference?.split('/')?.[1] as string;
      const orgName = (profile as Patient)?.managingOrganization?.display ?? '';
      localStorage.setItem('orgId', organizationId);
      localStorage.setItem('orgName', orgName);

      // Handle Flag request
      let flag;
      try {
        flag = await medplum.searchOne('Flag', `subject=Patient/${profile?.id}&identifier=company-admin`, {
          cache: 'no-cache',
        });
      } catch (flagError) {
        console.error('Error fetching flag:', flagError);
      }

      let isCompanyAdmin;

      if (flag) {
        isCompanyAdmin =
          profile?.resourceType === 'Patient' &&
          (profile as Patient)?.managingOrganization &&
          flag?.status === 'active';

        if (flag?.status === 'inactive' && profile?.resourceType === 'Patient') {
          localStorage.removeItem('companyAdmin');
          await medplum.signOut().then(() => {
            medplum.clearActiveLogin();
          });
          showNotification({ message: t('auth.you-are-not-authorized'), color: 'red' });
          navigate('/signin');
        }

        setFlag(flag);

        // Check if user is company admin
        if (isCompanyAdmin) {
          setIsCompanyAdmin(true);
          localStorage.setItem('companyAdmin', 'true');

          const invoices = await medplum.executeBot(getInvoices, { organizationId: organizationId }, undefined, {
            cache: 'no-cache',
          });

          const findInvoice = invoices?.data?.filter((item: any) => item?.object === 'invoice');

          setPaymentStatus(findInvoice?.every((i: any) => i?.status === 'paid') ? 'paid' : 'unpaid');

          if (findInvoice?.some((i: any) => i?.paid === false)) {
            showNotification({ message: t('auth.payment-not-found-org-deactivated'), color: 'red' });
            await medplum.executeBot(deactivateOrganization, {
              organizationId,
            });
          }
        } else {
          setIsCompanyAdmin(false);
        }
      } else {
        if (profile?.resourceType === 'Patient' && !isCompanyAdmin && !medplum.isProjectAdmin()) {
          await medplum.signOut().then(() => {
            medplum.clearActiveLogin();
          });
          showNotification({ message: t('auth.you-are-not-authorized'), color: 'red' });
          navigate('/signin');
        }
      }

      if (organizationId) {
        let org;
        try {
          org = await medplum.readResource('Organization', organizationId, { cache: 'no-cache' });
        } catch (orgError) {
          console.error('Error fetching organization:', orgError);
        }

        if (org) {
          setOrganization(org);
          setIsOrgDeactivated(org?.active === false);
          setIsAuthMeReady(false);
        }
      }
    } catch (error) {
      console.error(error);
      setIsAuthMeReady(false);
    }
  }, [medplum, setFlag, setIsCompanyAdmin, setOrgId, setOrganization, setPaymentStatus, setProfile, setUserId]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchAuthMe();
    }
  }, [fetchAuthMe, isLoggedIn]);

  const customConfig = [
    {
      title: 'company-admin-menu',
      links: [
        {
          label: t('users.users'),
          href: '/users',
          icon: '../../img/dashboard-assets/user-square.svg',
        },
        {
          label: t('teams.teams'),
          href: '/teams',
          icon: '../../img/dashboard-assets/users.svg',
        },
        // {
        //   label: t('overview.overview'),
        //   href: '/overview',
        //   icon: '../../img/dashboard-assets/fileld-menu.svg',
        // },
        {
          label: t('settings.settings'),
          href: '/organization-settings',
          icon: '../../img/dashboard-assets/setting.svg',
        },
      ],
    },
    {
      title: 'project-admin-menu',
      links: [
        {
          label: t('customers.customers'),
          href: '/customers',
          icon: '../../img/dashboard-assets/group-setting.svg',
        },
      ],
    },
  ];

  if (isLoggedIn && paymentStatus && paymentStatus !== 'paid') {
    return (
      <Modal opened withCloseButton={false} onClose={close} centered title={t('auth.account-on-hold')}>
        <Flex mt={12} direction="column" align="center" justify="center" h="100%">
          <Text size="md" mb={12}>
            {t('auth.account-on-hold-description')} <Link to="mailto:info@goecofit.com">info@goecofit.com</Link>
          </Text>
          <Flex align="center" justify="flex-end" w="100%">
            <Button
              onClick={async () => {
                await medplum.signOut();
                navigate('/signin');
                window.location.reload();
              }}
            >
              {t('auth.sign-out')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    );
  }

  if (isLoggedIn && isOrgDeactivated) {
    return (
      <Modal opened withCloseButton={false} onClose={close} centered title={t('auth.organization-deactivated')}>
        <Flex mt={12} direction="column" align="center" justify="center" h="100%">
          <Text size="md" mb={12}>
            {t('auth.organization-deactivated-description')}{' '}
            <Link to="mailto:info@goecofit.com">info@goecofit.com</Link> {t('auth.for-any-assistance')}.
          </Text>
          <Flex align="center" justify="flex-end" w="100%">
            <Button
              onClick={async () => {
                await medplum.signOut();
                navigate('/signin');
                window.location.reload();
              }}
            >
              {t('auth.sign-out')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    );
  }

  if (isAuthMeReady) {
    return <Loading />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppShell
        logo={<img src={'../../img/goecofit-logo.png'} style={{ height: '40px' }} />}
        pathname={location.pathname}
        searchParams={searchParams}
        version={MEDPLUM_VERSION}
        menus={customConfig}
        displayAddBookmark={!!config?.id}
        editProfileText={t('common.edit-profile')}
        signOutText={t('common.sign-out')}
        changePasswordText={t('common.change-password')}
        languageSwitchMenu={
          <LanguageSwitchMenu
            langvalue={langvalue}
            langImage={<img src={langImage} style={{ width: '20px' }} />}
            setLangImage={setLangImage}
            setLangValue={setLangValue}
            langMenuOpened={langMenuOpened}
            setLangMenuOpened={setLangMenuOpened}
          />
        }
      >
        <Suspense fallback={<Loading />}>
          <AppRoutes />
        </Suspense>
      </AppShell>
    </QueryClientProvider>
  );
}
