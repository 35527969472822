import {
  Box,
  Button,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  NativeSelect,
  Stepper,
  Switch,
  TextInput,
} from '@mantine/core';
import { Filter } from '@medplum/core';
import { ProjectMembership, ProjectMembershipAccess, SearchParameter } from '@medplum/fhirtypes';
import { Form, useMedplum } from '@medplum/react';
import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { IMaskInput } from 'react-imask';
import { INewOrganizationForm, useCreateNewOrganization } from '../../../queries/organization/useCreateNewOrganization';
import { showNotification } from '@mantine/notifications';
import { useGetStripeProducts } from '../../../queries/stripe/useGetStripeProducts';
import { useGetStripeCoupons } from '../../../queries/stripe/useGetStripeCoupons';
import { useGetStripeTaxCountries } from '../../../queries/stripe/useGetStripeTaxCountries';
import { useTranslation } from 'react-i18next';
import { useSendInvitationEmail } from '../../../queries/organization/useSendInvitationEmail';
import { getConfig } from '../../../config';

export interface CreateNewOrganizationModalProps {
  title: string;
  visible: boolean;
  resourceType: string;
  searchParam?: SearchParameter;
  filter?: Filter;
  defaultValue?: string;
  onCancel: () => void;
  fetchOrgList: () => void;
}

export function CreateNewOrganizationModal(props: CreateNewOrganizationModalProps): JSX.Element | null {
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState(0);
  const medplum = useMedplum();
  const { adminPatientAccessPolicy, createStripeCustomerAndSubscription, validateEuVatTaxId } = getConfig();

  const form = useForm({
    initialValues: {
      //Step 1
      companyName: '',
      city: '',
      address: '',
      zipCode: '',
      phone: '',
      isBillingAddressDifferent: false,
      billingCity: '',
      billingAddress: '',
      billingZipCode: '',
      //Step 2
      contactPersonFirstName: '',
      contactPersonLastName: '',
      contactPersonEmail: '',
      //Step 3
      plan: 'start',
      amountOfLicenses: '',
      discount: 'none',
      taxId: '',
      taxCountryCode: '',
      freeTrialDays: 0,
    } as INewOrganizationForm,

    validate: (values) => {
      if (active === 0) {
        return {
          companyName: !values.companyName ? t('customers.company-name-required') : null,
          city: !values.city ? t('customers.city-required') : null,
          address: !values.address ? t('customers.street-address-required') : null,
          // phone: !values.phone ? t('customers.phone-number-required') : null,
          zipCode: !values.zipCode ? t('customers.zip-code-required') : null,
          billingCity:
            values.isBillingAddressDifferent && !values.billingCity ? t('customers.billing-city-required') : null,
          billingZipCode:
            values.isBillingAddressDifferent && !values.billingZipCode
              ? t('customers.billing-zip-code-required')
              : null,
          billingAddress:
            values.isBillingAddressDifferent && !values.billingAddress ? t('customers.billing-address-required') : null,
        };
      }

      if (active === 1) {
        return {
          contactPersonFirstName: !values.contactPersonFirstName ? t('customers.first-name-required') : null,
          contactPersonLastName: !values.contactPersonLastName ? t('customers.last-name-required') : null,
          contactPersonEmail: /^\S+@\S+$/.test(values.contactPersonEmail) ? null : t('customers.invalid-email'),
        };
      }

      if (active === 2) {
        return {
          plan: !values.plan ? t('customers.plan-required') : null,
          amountOfLicenses: !values.amountOfLicenses ? t('customers.amounf-of-licenses-required') : null,
          discount: !values.discount ? t('customers.discount-required') : null,
          taxId: !values.taxId ? t('customers.tax-id-required') : null,
          taxCountryCode: !values.taxCountryCode ? t('customers.tax-country-code-required') : null,
        };
      }

      return {};
    },
  });

  //Verify EU VAT Tax ID
  const verifyVat = async (countryCode: string, value: string): Promise<any> => {
    return await medplum.executeBot(validateEuVatTaxId, {
      countryCode,
      value,
    });
  };

  const { data: stripeProducts } = useGetStripeProducts({
    enabled: props.visible,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        form.setFieldValue('amountOfLicenses', data?.data?.[0]?.id);
      }
    },
  });

  const { data: stripeCoupons } = useGetStripeCoupons({
    enabled: props.visible,
    refetchOnWindowFocus: false,
  });

  const { data: stripeTaxCountries } = useGetStripeTaxCountries({
    enabled: props.visible,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        form.setFieldValue('taxCountryCode', data?.find((c: any) => c?.countryCode === 'DE')?.countryCode);
      }
    },
  });

  const { mutateAsync: createNewOrganization, isLoading } = useCreateNewOrganization({
    onSuccess: async (resp: any) => await handleCreateStripeCustomerAndSubscription(resp),
    onError: (error: any) => {
      showNotification({ color: 'red', message: error.message });
    },
  });

  const handleCreateNewOrganization = async (values: INewOrganizationForm) => {
    const isValidTaxId = await verifyVat('DE', values.taxId);

    if (form.validate().hasErrors) {
      setActive((current) => {
        if (form.validate().hasErrors) {
          return current;
        }
        return current < 3 ? current + 1 : current;
      });
    } else if (!isValidTaxId?.isValid) {
      showNotification({
        color: 'red',
        message: t('customers.invalid-tax-id'),
      });
    } else {
      await createNewOrganization(values);
    }
  };

  const { mutateAsync: sendInvitationEmail } = useSendInvitationEmail();

  const handleSendInvitationEmail = async (userReference: string) => {
    await sendInvitationEmail({
      userReference,
      langCode: i18n.language,
    });
  };

  // CREATE STRIPE CUSTOMER AND SUBSCRIPTION AFTER ORGANIZATION CREATION
  const handleCreateStripeCustomerAndSubscription = async (resp: any) => {
    const userReference = resp?.entry?.find((x: any) => x?.resource?.resourceType === 'ProjectMembership')?.resource
      ?.user?.reference;
    const priceId = stripeProducts?.data?.find((x: any) => x?.id === form.values?.amountOfLicenses)?.default_price;
    const organizationId = resp?.entry?.find((x: any) => x?.resource?.resourceType === 'Organization')?.resource?.id;

    if (priceId && organizationId) {
      try {
        //THIS BOT CREATES STRIPE CUSTOMER AND SUBSCRIPTION
        await medplum.executeBot(createStripeCustomerAndSubscription, {
          formValues: {
            ...form.values,
            phone: form.values.phone.replace(/[^+\d]/g, ''),
          },
          priceId,
          organizationId,
        });

        const projectMemberShipResp: ProjectMembership = resp?.entry?.find(
          (x: any) => x?.resource?.resourceType === 'ProjectMembership'
        )?.resource;
        const respOrgId = resp?.entry?.find((x: any) => x?.resource?.resourceType === 'Organization')?.resource?.id;

        if (projectMemberShipResp && respOrgId) {
          await medplum.updateResource({
            ...projectMemberShipResp,
            resourceType: 'ProjectMembership',
            id: projectMemberShipResp?.id,
            access: [
              ...(projectMemberShipResp?.access as ProjectMembershipAccess[]),
              {
                policy: {
                  reference: `AccessPolicy/${adminPatientAccessPolicy}`,
                },
                parameter: [
                  {
                    name: 'provider_organization_id',
                    valueString: respOrgId,
                  },
                ],
              },
            ],
          });
        }

        showNotification({ color: 'green', message: t('customers.organization-created-success') });

        await handleSendInvitationEmail(userReference);
        showNotification({ color: 'green', message: t('customers.invitation-email-sent-to-contact-person') });
        props.fetchOrgList();
        props.onCancel();
      } catch (error) {
        showNotification({
          color: 'red',
          message: t('customers.subscription-error'),
        });
        console.error(error);
      }
    }
  };

  const plans = [
    { label: 'Start', value: 'start' },
    { label: 'Pro', value: 'pro' },
    { label: 'Premium', value: 'premium' },
  ];

  const licenses = React.useMemo(() => {
    return stripeProducts?.data?.map((product: any) => {
      return {
        label: `${product?.description}/company`,
        value: product?.id,
      };
    });
  }, [stripeProducts]);

  const discounts = React.useMemo(() => {
    return stripeCoupons?.data?.map((coupon: any) => {
      return {
        label: coupon?.name,
        value: coupon?.id,
      };
    });
  }, [stripeCoupons]);

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Modal title={props.title} size="xl" opened={props.visible} onClose={props.onCancel}>
      <Form onSubmit={async () => await handleCreateNewOrganization(form.values)}>
        <Grid mt={12}>
          <Grid.Col span={12}>
            <>
              <Stepper active={active} breakpoint="sm">
                <Stepper.Step
                  label={t('customers.organization-info')}
                  description={t('customers.organization-info-description')}
                >
                  <Box py={20}>
                    <TextInput
                      mb={12}
                      required
                      label={t('customers.company-name')}
                      placeholder={t('customers.company-name')}
                      {...form.getInputProps('companyName')}
                    />

                    <Grid mb={12}>
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          required
                          label={t('common.street-address')}
                          placeholder={t('common.street-address')}
                          mb={12}
                          {...form.getInputProps('address')}
                        />
                        <TextInput
                          required
                          label={t('common.zip-code')}
                          placeholder={t('common.zip-code')}
                          {...form.getInputProps('zipCode')}
                        />
                      </Grid.Col>
                      <Grid.Col xs={12} md={6}>
                        <TextInput
                          required
                          label={t('common.city')}
                          placeholder={t('common.city')}
                          mb={12}
                          {...form.getInputProps('city')}
                        />
                        <TextInput
                          required
                          label={t('common.country')}
                          placeholder={t('common.country')}
                          value="Germany"
                          disabled
                        />
                      </Grid.Col>
                    </Grid>

                    <Input.Wrapper mb={20} label={t('common.phone-number')} placeholder={t('common.phone-number')}>
                      <Input
                        component={IMaskInput}
                        mask="+49 (000) 0000-000000"
                        label={t('common.phone-number')}
                        placeholder={t('common.phone-number')}
                        {...form.getInputProps('phone')}
                      />
                    </Input.Wrapper>
                    <Switch
                      mb={12}
                      label={t('customers.use-different-billing-address')}
                      {...form.getInputProps('isBillingAddressDifferent')}
                    />
                    {form.values.isBillingAddressDifferent && (
                      <Group mb={12} spacing="xs" grow noWrap>
                        <TextInput
                          label={`${t('common.billing')} ${t('common.street-address')}`}
                          placeholder={`${t('common.billing')} ${t('common.street-address')}`}
                          {...form.getInputProps('billingAddress')}
                        />
                        <TextInput
                          label={`${t('common.billing')} ${t('common.zip-code')}`}
                          placeholder={`${t('common.billing')} ${t('common.zip-code')}`}
                          {...form.getInputProps('billingZipCode')}
                        />
                        <TextInput
                          label={`${t('common.billing')} ${t('common.city')}`}
                          placeholder={`${t('common.billing')} ${t('common.city')}`}
                          {...form.getInputProps('billingCity')}
                        />
                        <TextInput
                          label={`${t('common.billing')} ${t('common.country')}`}
                          placeholder={`${t('common.billing')} ${t('common.country')}`}
                          value="Germany"
                          disabled
                        />
                      </Group>
                    )}
                  </Box>
                </Stepper.Step>

                <Stepper.Step
                  label={t('common.contact-person')}
                  description={t('customers.contact-person-description')}
                >
                  <Box py={20}>
                    <Group mb={12} spacing="xs" grow noWrap>
                      <TextInput
                        required
                        label={t('common.first-name')}
                        placeholder={t('common.first-name')}
                        {...form.getInputProps('contactPersonFirstName')}
                      />
                      <TextInput
                        required
                        label={t('common.last-name')}
                        placeholder={t('common.last-name')}
                        {...form.getInputProps('contactPersonLastName')}
                      />
                    </Group>
                    <TextInput
                      mb={12}
                      required
                      label={t('common.email')}
                      placeholder={t('common.email')}
                      type="email"
                      {...form.getInputProps('contactPersonEmail')}
                    />
                  </Box>
                </Stepper.Step>

                <Stepper.Step label={t('customers.plan-details')} description={t('customers.plan-details-description')}>
                  <Group mb={12} spacing="xs" grow noWrap>
                    <NativeSelect
                      label={t('common.plan')}
                      required
                      defaultValue={plans?.[0]?.value}
                      data={plans ?? []}
                      disabled
                      {...form.getInputProps('plan')}
                    />
                    <NativeSelect
                      required
                      label={t('customers.amount-of-licenses')}
                      data={licenses ?? []}
                      {...form.getInputProps('amountOfLicenses')}
                    />
                  </Group>
                  <Group mb={12} spacing="xs" grow noWrap>
                    <NativeSelect
                      label={t('customers.discount')}
                      required
                      data={!!discounts ? [...discounts, { label: 'None', value: 'none' }] : []}
                      {...form.getInputProps('discount')}
                    />
                    <TextInput
                      type="number"
                      min={0}
                      max={360}
                      label={t('customers.free-trial-days')}
                      placeholder={t('customers.free-trial-days')}
                      {...form.getInputProps('freeTrialDays')}
                    />
                  </Group>
                  <Group mb={12} spacing="xs" grow noWrap>
                    <NativeSelect
                      label={t('customers.tax-country')}
                      required
                      data={stripeTaxCountries?.map((c: any) => c?.countryCode) ?? []}
                      {...form.getInputProps('taxCountryCode')}
                    />
                    <TextInput
                      required
                      label={t('customers.tax-id')}
                      minLength={9}
                      type="number"
                      {...form.getInputProps('taxId')}
                    />
                  </Group>
                </Stepper.Step>
              </Stepper>

              <Group position="right" mt="xl">
                {active !== 0 && (
                  <Button variant="default" onClick={prevStep}>
                    {t('common.back')}
                  </Button>
                )}
                {active !== 2 && <Button onClick={nextStep}>{t('common.next')}</Button>}
                {active === 2 && <Button type="submit">{t('common.create')}</Button>}
              </Group>
            </>
          </Grid.Col>
        </Grid>
      </Form>
    </Modal>
  );
}
