import { ProfileResource } from '@medplum/core';
import { Flag, Organization } from '@medplum/fhirtypes';
import { create } from 'zustand';

interface AuthMeState {
  orgId: string | undefined;
  flag: Flag | undefined;
  organization: Organization | undefined;
  isCompanyAdmin: boolean | undefined;
  userId: string | undefined;
  paymentStatus: string | undefined;
  profile: ProfileResource | undefined;
  setOrgId: (value: string | undefined) => void;
  setFlag: (value: Flag | undefined) => void;
  setOrganization: (value: Organization | undefined) => void;
  setIsCompanyAdmin: (value: boolean | undefined) => void;
  setUserId: (value: string | undefined) => void;
  setPaymentStatus: (value: string | undefined) => void;
  setProfile: (value: ProfileResource | undefined) => void;
}

const initialState = {
  orgId: undefined,
  flag: undefined,
  organization: undefined,
  isCompanyAdmin: undefined,
  userId: undefined,
  paymentStatus: undefined,
  profile: undefined,
};

export const useAuthMeStore = create<AuthMeState>()((set) => ({
  ...initialState,
  setOrgId: (val: string | undefined) => set(() => ({ orgId: val })),
  setFlag: (val: Flag | undefined) => set(() => ({ flag: val })),
  setOrganization: (val: Organization | undefined) => set(() => ({ organization: val })),
  setIsCompanyAdmin: (val: boolean | undefined) => set(() => ({ isCompanyAdmin: val })),
  setUserId: (val: string | undefined) => set(() => ({ userId: val })),
  setPaymentStatus: (val: string | undefined) => set(() => ({ paymentStatus: val })),
  setProfile: (val: ProfileResource | undefined) => set(() => ({ profile: val })),
}));
