import * as React from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { Center, Flex, Grid, Image, Text, Title, useMantineTheme } from '@mantine/core';
import { DownloadApp } from './DownloadApp';
import { getConfig } from '../../config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const UserInvite = () => {
  const { t } = useTranslation();
  const { primaryColor } = useMantineTheme();
  const { googlePlayStoreUrl, appStoreUrl } = getConfig();
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  React.useEffect(() => {
    if (isMobile && isIOS) {
      const deeplink = appStoreUrl;
      window.location.href = deeplink;
    }

    if (isMobile && isAndroid) {
      const deeplink = googlePlayStoreUrl;
      window.location.href = deeplink;
    }
  }, []);

  return (
    <div style={{ height: 'auto', padding: '60px' }}>
      <Center w={'100%'} h={'100%'}>
        <Grid>
          <Grid.Col xs={12} sm={6}>
            <Center>
              <Image
                style={{
                  width: '70%',
                  height: '70%',
                  maxWidth: 1000,
                  maxHeight: 1000,
                  objectFit: 'contain',
                }}
                src={selectedLanguage === 'en' ? 'img/user_invite_en.png' : '/img/user_invite_de.png'}
              />
            </Center>
          </Grid.Col>
          <Grid.Col xs={12} sm={6}>
            <Flex direction="column" justify="space-between" align="center" sx={{ height: '100%' }}>
              <Title fw={'normal'} color={primaryColor} order={3} align="left" w={'100%'}>
                {t('auth.download-app')}
              </Title>
              <DownloadApp appStoreUrl={appStoreUrl} googlePlayStoreUrl={googlePlayStoreUrl} />
              <Flex w={'100%'} direction="column">
                <Image maw={160} radius="md" src="/img/goecofit-logo.png" alt="Logo" />
                <Text color={primaryColor} size={'xl'}>
                  {t('auth.contact-us')}
                </Text>
                <Text size={'md'} color={'dimmed'} align="left">
                  {t('auth.contact-us-description')}. <br />
                  <Link style={{ color: primaryColor }} color={primaryColor} to="mailto:support@goecofit.com">
                    support@goecofit.com
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Center>
    </div>
  );
};

export default UserInvite;
