import { Button, Center, Group, PasswordInput, Stack, Title } from '@mantine/core';
import { normalizeOperationOutcome } from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import { Document, Form, getErrorsForInput, useMedplum } from '@medplum/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ChangePasswordPage(): JSX.Element {
  const { t } = useTranslation();
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [success, setSuccess] = useState(false);

  return (
    <Document width={450}>
      <Form
        style={{ maxWidth: 400 }}
        onSubmit={(formData: Record<string, string>) => {
          setOutcome(undefined);
          medplum
            .post('auth/changepassword', formData)
            .then(() => setSuccess(true))
            .catch((err) => setOutcome(normalizeOperationOutcome(err)));
        }}
      >
        <Center sx={{ flexDirection: 'column' }}>
          <img src="../img/goecofit-favicon.png" style={{ width: '50px' }} />
          <Title>{t('auth.change-password')}</Title>
        </Center>
        {!success && (
          <Stack spacing="xl" mt="xl">
            <PasswordInput
              name="oldPassword"
              label={t('auth.old-password')}
              required={true}
              autoFocus={true}
              error={getErrorsForInput(outcome, 'oldPassword')}
            />
            <PasswordInput
              name="newPassword"
              label={t('auth.new-password')}
              required={true}
              error={getErrorsForInput(outcome, 'newPassword')}
            />
            <PasswordInput
              name="confirmPassword"
              label={t('auth.confirm-new-password')}
              required={true}
              error={getErrorsForInput(outcome, 'confirmPassword')}
            />
            <Group position="right" mt="xl" noWrap>
              <Button type="submit" className="change-pwd-btn">
                {t('auth.change-password')}
              </Button>
            </Group>
          </Stack>
        )}
        {success && <div data-testid="success">{t('auth.change-password-success')}</div>}
      </Form>
    </Document>
  );
}
