import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import de from './locales/de.json';

i18n.use(initReactI18next).init({
  resources: {
    en,
    de,
  },
  lng: 'de',
  fallbackLng: 'de', // fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
