import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../../config';

export interface IDeleteGroupProps {
  groupId: string | undefined;
}

export const useDeleteGroup = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const {deleteGroup} = getConfig()

  return useMutation(async ({ groupId }: IDeleteGroupProps) => {
    return medplum.executeBot(deleteGroup, {
      groupId,
    });
  }, options);
};
