import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../../config';

export interface ISendInvitationEmailProps {
  userReference: string;
  langCode?: string;
}

export const useSendInvitationEmail = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const { sendInvitationEmail } = getConfig();

  return useMutation(async ({ userReference, langCode }: ISendInvitationEmailProps) => {
    return medplum.executeBot(sendInvitationEmail, {
      userReference,
      langCode,
    });
  }, options);
};
