import React from 'react';
import { Grid, TextInput, Text, Input, NativeSelect, Alert, Switch } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { UpdateOrganizationFormData } from './OrganizationEdit';
import { AddressInput } from '@medplum/react';
import { Address } from '@medplum/fhirtypes';
import { IconLicense } from '@tabler/icons-react';
interface Props {
  isUpgradePlanOpen: boolean;
  setIsUpgradePlanOpen: (val: boolean) => void;
  currentPlan: any;
  planIds: { productId: string | undefined; priceId: string | undefined };
  setPlanIds: (val: { productId: string | undefined; priceId: string | undefined }) => void;
  upgradeProducts: any[];
  formData: UpdateOrganizationFormData;
  setFormData: (formData: UpdateOrganizationFormData) => void;
}
export const OrganizationForm: React.FC<Props> = ({
  isUpgradePlanOpen,
  setIsUpgradePlanOpen,
  formData,
  setFormData,
  upgradeProducts,
  planIds,
  setPlanIds,
  currentPlan,
}) => {
  const { t } = useTranslation();

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    // @ts-ignore
    setFormData((prevData: UpdateOrganizationFormData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressChange = (value: Address, type: 'work' | 'billing') => {
    if (type === 'billing') {
      // @ts-ignore
      setFormData((prevData: UpdateOrganizationFormData) => ({
        ...prevData,
        billingAddress: value,
      }));
    }

    if (type === 'work') {
      // @ts-ignore
      setFormData((prevData: UpdateOrganizationFormData) => ({
        ...prevData,
        workAddress: value,
      }));
    }
  };

  const handleSetPlanIds = (value: string) => {
    const findProductId = upgradeProducts?.find((u) => u?.default_price === value)?.id;

    setPlanIds({
      priceId: value,
      productId: findProductId,
    });
  };

  return (
    <>
      <form>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('settings.organization-name')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={8}>
            <TextInput
              placeholder={t('settings.organization-name')}
              name="orgName"
              value={formData.orgName}
              onChange={handleInputChange}
            />
          </Grid.Col>
        </Grid>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.contact-person')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={4}>
            <TextInput
              placeholder={t('common.first-name')}
              name="firstName"
              onChange={handleInputChange}
              value={formData.firstName}
            />
          </Grid.Col>
          <Grid.Col span={12} lg={4}>
            <TextInput
              placeholder={t('common.last-name')}
              name="lastName"
              onChange={handleInputChange}
              value={formData.lastName}
            />
          </Grid.Col>
        </Grid>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.email')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={8}>
            <TextInput disabled placeholder={t('email')} name="email" value={formData.email} readOnly />
          </Grid.Col>
        </Grid>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.phone-number')}</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={4}>
            <Input
              component={IMaskInput}
              mask="+49 (000) 0000-000000"
              label={t('common.phone-number')}
              placeholder={t('common.phone-number')}
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleInputChange}
            />
          </Grid.Col>
        </Grid>
        {/* <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.contact-person-phone-number')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={4}>
            <Input
              component={IMaskInput}
              mask="+49 (000) 0000-000000"
              label={t('common.contact-person-phone-number')}
              placeholder={t('common.contact-person-phone-number')}
              name="contactNo"
              value={formData.contactNo}
              onChange={handleInputChange}
            />
          </Grid.Col>
        </Grid> */}
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.address')}</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={8}>
            <AddressInput
              defaultValue={formData?.workAddress}
              name="workAddress"
              onChange={(value) => handleAddressChange(value, 'work')}
            />
          </Grid.Col>
        </Grid>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.billing-address')}</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={8}>
            <AddressInput
              defaultValue={formData?.billingAddress}
              name="billingAddress"
              onChange={(value) => handleAddressChange(value, 'billing')}
            />
          </Grid.Col>
        </Grid>

        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('Plan')}</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={8}>
            <Alert icon={<IconLicense size="1rem" />} title={t('common.current-plan')} color="blue">
              <Text>
                {currentPlan?.name} {t('common.plan')}
              </Text>
              <Text>
                {' '}
                {currentPlan?.metadata?.licensesAmount} {t('common.licenses')} -
                {currentPlan?.description?.split('-')?.[1]}
              </Text>
            </Alert>
            {upgradeProducts.length > 0 && (
              <>
                <Switch
                  label={t('common.upgrade-plan')}
                  mt={12}
                  checked={isUpgradePlanOpen}
                  onChange={(event) => setIsUpgradePlanOpen(event.currentTarget.checked)}
                />
                <NativeSelect
                  mt={12}
                  label={t('common.select-a-plan-to-upgrade')}
                  defaultValue={planIds?.priceId}
                  value={planIds?.priceId}
                  disabled={!planIds || !upgradeProducts || !isUpgradePlanOpen}
                  onChange={(event) => handleSetPlanIds(event.currentTarget.value)}
                  data={
                    upgradeProducts?.map((p) => {
                      return {
                        label: p?.description,
                        value: p?.default_price,
                      };
                    }) ?? []
                  }
                />
              </>
            )}
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};
