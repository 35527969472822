import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';
import { Consent, Flag, Group, Patient } from '@medplum/fhirtypes';
import { useAuthMeStore } from '../../store/useAuthMeStore';

export type PatientTableResp = Patient & { groupId: string; groupName: string; isCompanyAdmin: boolean; status: string};

const keys = {
  all: [QueryKeys.GET_PATIENTS] as const,
  byParams: (
    page: number,
    count: number,
    searchText: string,
    nameSort: 'name' | '-name' | undefined,
    lastUpdatedSort: '_lastUpdated' | '-_lastUpdated'
  ) => [...keys.all, page, count, searchText, nameSort, lastUpdatedSort],
};

export const useGetPatients = (
  page: number,
  count: number,
  searchText: string,
  nameSort: 'name' | '-name' | undefined,
  lastUpdatedSort: '_lastUpdated' | '-_lastUpdated',
  options?: UseQueryOptions<any | undefined, Error, any | undefined, readonly [...(string | number | undefined)[]]>
): UseQueryResult<any | undefined, Error> => {
  const medplum = useMedplum();
  const orgId = localStorage.getItem('orgId') ?? useAuthMeStore((s) => s.orgId);
  const authUserId = useAuthMeStore((s) => s?.userId);

  const patientQuery = (): string => {
    const patientQuery = `Patient?organization=Organization/${orgId}&_count=${count}&_offset=${
      page === 1 ? 0 : count * (page - 1)
    }&name:contains=${searchText}`;

    if (nameSort) {
      const newQuery = patientQuery.concat(`&_sort=${nameSort}`);
      return newQuery;
    } else {
      const newQuery = patientQuery.concat(`&_sort=${lastUpdatedSort}`);
      return newQuery;
    }
  };

  return useQuery<
    PatientTableResp | undefined,
    Error,
    PatientTableResp | undefined,
    readonly [...(string | number | undefined)[]]
  >(
    keys.byParams(page, count, searchText, nameSort, lastUpdatedSort),
    async (): Promise<any> =>
      await medplum
        .executeBatch(
          {
            resourceType: 'Bundle',
            type: 'batch',
            entry: [
              {
                request: {
                  method: 'GET',
                  url: `Patient?organization=Organization/${orgId}&_summary=count`,
                },
              },
              {
                request: {
                  method: 'GET',
                  url: patientQuery(),
                },
              },
              {
                request: {
                  method: 'GET',
                  url: `Group?managing-entity=Organization/${orgId}`,
                },
              },
              {
                request: {
                  method: 'GET',
                  url: `Flag?author=Organization/${orgId}`,
                },
              },
              {
                request: {
                  method: 'GET',
                  url: `Consent?status=draft&organization=Organization/${orgId}`,
                },
              },
              {
                request: {
                  method: 'GET',
                  url: `Patient?organization=Organization/${orgId}&active=true&_summary=count`,
                },
              },
              {
                request: {
                  method: 'GET',
                  url: `Patient?organization=Organization/${orgId}&active:not=true&_summary=count`,
                },
              },
              {
                request: {
                  method: 'GET',
                  url: `Consent?status=draft&organization=Organization/${orgId}&_summary=count`,
                },
              },
            ],
          },
          {
            cache: 'no-cache',
          }
        )
        .then((resp: any) => {
          const total = resp?.entry?.[0]?.resource?.total;
          const groups = resp?.entry?.[2]?.resource?.entry?.flatMap((x: any) => x?.resource) as Group[];
          const flags = resp?.entry?.[3]?.resource?.entry?.flatMap((x: any) => x?.resource) as Flag[];
          const consents = resp?.entry?.[4]?.resource?.entry?.flatMap((x: any) => x?.resource) as Consent[];
          const activePatientTotal = resp?.entry?.[5]?.resource?.total;
          const inactivePatientTotal = resp?.entry?.[6]?.resource?.total;
          const pendingPatientTotal = resp?.entry?.[7]?.resource?.total;

          const patients: PatientTableResp = resp?.entry?.[1]?.resource?.entry
            ?.flatMap((p: { fullUrl: string; resource: Patient }) => {
              const patientGroup = groups?.find(
                (g: Group) => g?.member?.some((m) => m?.entity?.reference === `Patient/${p?.resource?.id}`)
              );
              const consentStatus = consents?.find((c: Consent) => c?.identifier?.find((x) => x?.system === 'patient-email')?.value === p?.resource?.telecom?.find((t) => t?.system ==='email')?.value)?.status;
              
              return {
                ...p?.resource,
                groupId: patientGroup?.id,
                groupName: patientGroup?.name,
                status: consentStatus === 'draft' ? 'pending' : p?.resource?.active ? 'active' :  'inactive',
                isCompanyAdmin:
                  flags?.find((f: Flag) => f?.subject?.reference === `Patient/${p?.resource?.id}`)?.status === 'active',
              };
            })
            ?.sort((a: any, b: any) => {
              // Prioritize the logged-in user
              if (a.id === authUserId) return -1;
              if (b.id === authUserId) return 1;

              // Next, prioritize company admins
              if (b.isCompanyAdmin && !a.isCompanyAdmin) return 1;
              if (a.isCompanyAdmin && !b.isCompanyAdmin) return -1;

              // Finally, sort by isCompanyAdmin status
              return b.isCompanyAdmin - a.isCompanyAdmin;
            });

          return {patients, total, activePatientTotal,inactivePatientTotal,pendingPatientTotal, groups};
        }),
    options
  );
};
