import { Button, Center, Group, Stack, TextInput, Title, createStyles } from '@mantine/core';
import { normalizeOperationOutcome } from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import {
  Container,
  Document,
  Form,
  getErrorsForInput,
  getIssuesForExpression,
  initRecaptcha,
  OperationOutcomeAlert,
  useMedplum,
} from '@medplum/react';
import React, { useEffect, useState } from 'react';
import { getConfig } from './config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  secondaryTitle: {
    color: '#272D41',
    float: 'left',
    fontSize: '20px',
    lineHeight: '1.1',
    fontWeight: 700,
    marginBottom: '8px',
  },
}));

export function ResetPasswordPage(): JSX.Element {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const config = getConfig();
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (config.recaptchaSiteKey) {
      initRecaptcha(config.recaptchaSiteKey);
    }
  }, [config.recaptchaSiteKey]);

  return (
    <Container w={'100%'} h={'100%'}>
      <Center w={'100%'} h={'100%'}>
        <Document w={450} p={20} className="reset-password">
          <Form
            style={{ width: '100%' }}
            onSubmit={async (formData: Record<string, string>) => {
              medplum
                .post('auth/resetpassword', {
                  ...formData,
                  recaptchaToken: config.recaptchaSiteKey,
                  projectId: config.projectId,
                  sendEmail: false,
                })
                .then(() => setSuccess(true))
                .catch((err: any) => setOutcome(normalizeOperationOutcome(err)));
            }}
          >
            <Stack spacing={0} align="center">
              <Link to={'/signin'}>
                <img src="../img/goecofit-favicon.png" style={{ width: '50px' }} />
              </Link>
              <Title mt={20} className={classes.secondaryTitle} variant="h3" fw={700}>
                {t('common.goecofit')} {t('auth.reset-password').toLowerCase()}
              </Title>
            </Stack>
            <Stack spacing="xl">
              <OperationOutcomeAlert issues={getIssuesForExpression(outcome, undefined)} />
              {!success && (
                <>
                  <TextInput
                    name="email"
                    type="email"
                    label={t('common.email')}
                    placeholder="name@domain.com"
                    required={true}
                    autoFocus={true}
                    error={getErrorsForInput(outcome, 'email')}
                  />
                  <Group position="right" noWrap>
                    <Button type="submit">{t('auth.reset-password')}</Button>
                  </Group>
                </>
              )}
              {success && <div>{t('auth.reset-password-success-message')}</div>}
            </Stack>
          </Form>
        </Document>
      </Center>
    </Container>
  );
}
