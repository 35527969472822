import React from 'react';

export interface LogoProps {
  size: number;
  fill?: string;
}

export function Logo(props: LogoProps): JSX.Element {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491 491" style={{ width: props.size, height: props.size }}>
    <title>PragmaConnect Logo</title>
      <g clip-path="url(#clip0_1161_8574)">
        <path
          d="M71.6604 0H57.9504C57.3926 0 56.9404 0.452192 56.9404 1.01V14.72C56.9404 15.2778 57.3926 15.73 57.9504 15.73H71.6604C72.2182 15.73 72.6704 15.2778 72.6704 14.72V1.01C72.6704 0.452192 72.2182 0 71.6604 0Z"
          fill="url(#paint0_linear_1161_8574)"
        />
        <path
          d="M71.39 31.13H59.46C58.75 31.13 58.18 31.7 58.18 32.41V44.34C58.18 45.05 57.61 45.62 56.9 45.62H43.73C43.02 45.62 42.45 45.05 42.45 44.34V32.41C42.45 31.7 41.88 31.13 41.17 31.13H29.24C28.53 31.13 27.96 30.56 27.96 29.85V16.68C27.96 15.97 28.53 15.4 29.24 15.4H41.17C41.88 15.4 42.45 14.83 42.45 14.12V2.19C42.45 1.92 42.55 1.68 42.7 1.47C40.63 1.11 38.51 0.900002 36.34 0.900002C16.27 0.910002 0 17.18 0 37.24C0 57.3 16.27 73.58 36.34 73.58C56.41 73.58 72.68 57.31 72.68 37.24C72.68 35.07 72.48 32.95 72.11 30.88C71.9 31.02 71.67 31.13 71.39 31.13Z"
          fill="url(#paint1_linear_1161_8574)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1161_8574"
          x1="56.9404"
          y1="7.87"
          x2="72.6804"
          y2="7.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2F67AD" />
          <stop offset="0.6" stop-color="#3F80BF" />
          <stop offset="1" stop-color="#478DC9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1161_8574"
          x1="0"
          y1="37.24"
          x2="72.68"
          y2="37.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.17" stop-color="#2F67AD" />
          <stop offset="0.59" stop-color="#478DC9" />
        </linearGradient>
        <clipPath id="clip0_1161_8574">
          <rect width="72.68" height="73.58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
