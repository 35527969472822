import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';

const activeSummaryKeys = {
  all: [QueryKeys.GET_GROUP_DETAIL_AND_GOALS] as const,
  byOrgId: (groupId: string | undefined) => [...activeSummaryKeys.all, groupId],
};

export const useGetGroupDetailAndGoals = (
  groupId: string | undefined,
  options?: UseQueryOptions<any | undefined, Error, any | undefined, readonly [...(string | undefined)[]]>
): UseQueryResult<any | undefined, Error> => {
  const medplum = useMedplum();

  return useQuery<any | undefined, Error, any | undefined, readonly [...(string | undefined)[]]>(
    activeSummaryKeys.byOrgId(groupId),
    async () =>
      await medplum
        .executeBatch({
          resourceType: 'Bundle',
          type: 'batch',
          entry: [
            {
              request: {
                method: 'GET',
                url: `Group/${groupId}`,
              },
            },
            {
              request: {
                method: 'GET',
                url: `Goal?subject=Group/${groupId}`,
              },
            },
          ],
        })
        .then((resp: any) => {
          return {
            group: resp?.entry?.[0]?.resource,
            goals: resp?.entry?.[1]?.resource?.entry?.flatMap((x: any) => x?.resource),
          };
        }),
    options
  );
};
