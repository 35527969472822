/**
 * axios setup to use mock service
 */
import axios from 'axios';

const axiosServices = axios.create({ baseURL: 'https://greener.fit:8103/' });

const axiosServices1 = axios.create({ baseURL: 'https://dashboard.dev.ovok.com/api' });

const axiosServices2 = axios.create({ baseURL: 'https://api.dev.ovok.com/greenerfit' });

export default axiosServices;

export  {axiosServices1, axiosServices2 };