import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';
import { Consent } from '@medplum/fhirtypes';

const keys = {
  all: [QueryKeys.GET_CONSENTS] as const,
  byParams: (status: string) => [...keys.all, status],
};

export const useGetConsents = (
  status: string,
  orgId: string | null,
  options?: UseQueryOptions<Consent[] | undefined, Error, Consent[] | undefined, readonly [...(string | undefined)[]]>
): UseQueryResult<Consent[] | undefined, Error> => {
  const medplum = useMedplum();

  return useQuery<Consent[] | undefined, Error, Consent[] | undefined, readonly [...(string | undefined)[]]>(
    keys.byParams(status),
    async () =>
      await medplum
        .search('Consent', `status=${status}&organization=Organization/${orgId}`, { cache: 'no-cache' })
        ?.then((resp) => resp?.entry?.map((e) => e?.resource as Consent)),
    options
  );
};
