import { Alert, Title, Grid, Text, useMantineTheme, Flex } from '@mantine/core';
import { Document, useMedplum } from '@medplum/react';
import { RegisterForm } from './patient/auth/RegisterForm';
import { IconAlertCircle } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfig, isRegisterEnabled } from './config';
import { useTranslation } from 'react-i18next';

export function RegisterPage(): JSX.Element | null {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const config = getConfig();
  const {colors} = useMantineTheme();
  const {t} = useTranslation()

  useEffect(() => {
    if (medplum.getProfile()) {
      navigate('/');
    }
  }, [medplum, navigate]);

  if (!isRegisterEnabled()) {
    return (
      <Document width={450}>
        <Alert icon={<IconAlertCircle size={16} />} title="New projects disabled" color="red">
          New projects are disabled on this server.
        </Alert>
      </Document>
    );
  }

  return (
    <Grid className="register" p={12}>
      <RegisterForm
        type="project"
        onSuccess={() => navigate('/')}
        googleClientId={config.googleClientId}
        recaptchaSiteKey={config.recaptchaSiteKey}
      >
        <img src="/img/goecofit-favicon.png" style={{ width: '80px', marginBottom: 20 }} />
        <Flex direction='column' mb={20}>
        <Title mb={12} order={3} >{t('auth.register-in-3-steps')}</Title>
        <Text color={colors.gray[6]} size='sm'>1{")"} {t('auth.register-step-1')}</Text>
        <Text color={colors.gray[6]}  size='sm'>2{")"} {t('auth.register-step-2')}</Text>
        <Text color={colors.gray[6]}  size='sm'>3{")"} {t('auth.register-step-3')}</Text>
        </Flex>
      </RegisterForm>
    </Grid>
  );
}
