export enum QueryKeys {
  CREATE_NEW_ORGANIZATION = 'createNewOrganization',
  GET_STRIPE_PRODUCTS = 'getStripeProducts',
  GET_STRIPE_COUPONS = 'getStripeCoupons',
  GET_GROUP_DETAIL_AND_GOALS = 'getGroupDetailAndGoals',
  GET_GROUPS = 'getGroups',
  DELETE_GROUP = 'deleteGroup',
  GET_PATIENTS = 'getPatients',
  GET_PATIENT_BY_ID = 'getPatientById',
  GET_GROUP_BY_PATIENT_ID = 'getGroupByPatientId',
  GET_ORGANIZATION_LIST = 'getOrganizationList',
  GET_ORGANIZATION_BY_ID = 'getOrganizationById',
  GET_STRIPE_TAX_COUNTRIES = 'getStripeTaxCountries',
  GET_ACCOUNT = 'getAccount',
  GET_SUBSCRIPTION_BY_ID = 'getStripeSubscriptionById',
  GET_OVERVIEW_DATA = 'getOverviewData',
  GET_TEAMS = 'getTeams',
  GET_CONSENTS = 'getConsents',
}
