import {
  ActionIcon,
  Button,
  Center,
  Flex,
  NativeSelect,
  Pagination,
  Paper,
  Table,
  Text,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
  Tooltip,
  Loader,
  ScrollArea,
  Grid,
} from '@mantine/core';
import { Group } from '@medplum/fhirtypes';
import { Container, useMedplum } from '@medplum/react';
import { IconPencil, IconPlus, IconRefresh, IconSearch, IconTrash } from '@tabler/icons-react';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../queries/query-keys';
import moment from 'moment';
import { useGetTeamsTable } from '../../queries/group/useGetTeamsTable';
import { CreateGroup } from './components/CreateGroup';
import { useDeleteGroup } from '../../queries/group/useDeleteGroup';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

const TeamsPage = () => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const medplum = useMedplum();
  const queryClient = useQueryClient();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(5);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupValue, setPopupValue] = useState<'create' | 'update'>('create');
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>();

  const { data: teamsData, isLoading } = useGetTeamsTable(page, count, debouncedSearchText);

  const debouncedHandleInputChange = _.debounce((value) => {
    setDebouncedSearchText(value);
  }, 1000);

  const handleSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedHandleInputChange(value);
  };

  const refreshResults = useCallback(async () => {
    await queryClient.resetQueries(QueryKeys.GET_TEAMS);
  }, [medplum]);

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleOpenAddEditGroupModal = (type: 'create' | 'update', resource?: Group) => {
    setPopupValue(type);
    setPopupOpen(true);
    setSelectedGroup(resource);
  };

  const { mutateAsync: deleteGroup } = useDeleteGroup({
    onSuccess: () => {
      showNotification({ color: 'green', message: t('teams.team-deleted-success') });
      refreshResults();
    },
    onError: (error: any) => {
      showNotification({ color: 'red', message: error.message });
    },
  });

  const handleDeleteGroup = async (groupId: any, groupName: string) => {
    const confirmation = confirm(`${t('deleteMessageGroup')} ${groupName}?`);
    if (confirmation) {
      await deleteGroup({ groupId });
    }
  };

  const rows = teamsData?.teams?.map((r: Group) => (
    <tr key={r?.id}>
      <td style={{ minWidth: 200 }}>{r?.name}</td>
      <td style={{ minWidth: 140 }}>{moment(r?.meta?.lastUpdated).format('DD MMM YYYY, HH:mm')}</td>
      <td>{r?.member?.filter((m) => m?.inactive !== true)?.length ?? 0}</td>
      <td>
        <Flex align="center" justify="flex-end">
          <Tooltip label={t('common.edit')}>
            <ActionIcon onClick={() => handleOpenAddEditGroupModal('update', r)}>
              <IconPencil color={theme.colors.blue[8]} size={18} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('common.delete')}>
            <ActionIcon ml={12} onClick={() => handleDeleteGroup(r?.id, r?.name ?? '')}>
              <IconTrash color={theme.colors.red[8]} size={18} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </td>
    </tr>
  ));

  return (
    <>
      <Paper shadow="xs" m="md" className="patient-list">
        <div className={classes.root} data-testid="search-control">
          <Flex px={12} direction="column" sx={{ borderBottom: '1px solid #f3f3f3' }}>
            <Flex py={12} direction="column">
              <Title fw="bold" order={2}>
                {t('teams.teams')}
              </Title>
              <Text color="grey" size="sm">
                {t('teams.teams-subheadline')}.
              </Text>
            </Flex>
            <Grid py={12} sx={{ borderTop: '1px solid #f3f3f3' }}>
              <Grid.Col xs={12} sm={6}>
                <Grid>
                  <Grid.Col xs={12} sm={4}>
                    <TextInput
                      placeholder={`${t('common.search')}...`}
                      icon={<IconSearch size={20} />}
                      value={searchText}
                      mr={12}
                      onChange={handleSearchTextChange}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={8}>
                    <Flex align="center" direction="row">
                      <Button
                        onClick={() => handleOpenAddEditGroupModal('create')}
                        size="sm"
                        mr={12}
                        leftIcon={<IconPlus size={14} />}
                      >
                        {t('common.create')}
                      </Button>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <Flex direction="row" align="center" justify="flex-end">
                  {teamsData && (
                    <Text size="xs" color="dimmed">
                      {page * count - (count - 1)} - {page * count > teamsData?.total ? teamsData?.total : page * count}{' '}
                      of {`${teamsData?.total}`}
                    </Text>
                  )}
                  <ActionIcon onClick={refreshResults} title="Refresh">
                    <IconRefresh size="1.125rem" />
                  </ActionIcon>
                </Flex>
              </Grid.Col>
            </Grid>
          </Flex>
          {isLoading ? (
            <Center w="100%" p={20}>
              <Loader />
            </Center>
          ) : (
            <ScrollArea>
              <Table highlightOnHover withColumnBorders striped horizontalSpacing="sm" verticalSpacing="sm">
                <thead>
                  <tr>
                    <th>{t('table.name')}</th>
                    <th>{t('table._lastUpdated')}</th>
                    <th>{t('table.member')}</th>
                    <th style={{ textAlign: 'end' }}>{t('table.action')}</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </ScrollArea>
          )}
          {teamsData?.total === 0 && (
            <Container>
              <Center style={{ height: 150 }}>
                <Text size="xl" color="dimmed">
                  {t('common.no-results')}
                </Text>
              </Center>
            </Container>
          )}
          {teamsData?.total !== undefined && (
            <Flex direction="row" align="center" justify="space-between" m="md" p="md">
              <Flex />
              <Pagination value={page} onChange={setPage} total={Math.ceil(teamsData?.total / count)} />
              <Flex direction="row" align="center" sx={{ alignSelf: 'flex-end' }}>
                <Text size="sm" color="dimmed">
                  {t('table.rows-per-page')}:
                </Text>
                <NativeSelect
                  size="sm"
                  ml={12}
                  value={count}
                  data={['5', '10', '20', '50', '100']}
                  onChange={(e) => setCount(Number(e.target.value))}
                />
              </Flex>
            </Flex>
          )}
        </div>
      </Paper>
      {isPopupOpen && (
        <CreateGroup
          loadResults={refreshResults}
          selectedGroup={selectedGroup}
          open={isPopupOpen}
          type={popupValue}
          closePopup={closePopup}
        />
      )}
    </>
  );
};

export default TeamsPage;

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  table: {},

  tr: {
    height: '55px',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));
