import { useMedplum } from '@medplum/react';
import { UseMutationOptions, useMutation } from 'react-query';
import { getConfig } from '../../config';

export interface IDeactivateUserProps {
  patientId: string;
}

export const useActivateUser = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const { activateUser } = getConfig();

  return useMutation(async ({ patientId }: IDeactivateUserProps) => {
    return medplum.executeBot(activateUser, {
      patientId,
    });
  }, options);
};
