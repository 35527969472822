import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';
import { SearchRequest } from '@medplum/core';
import { Organization } from '@medplum/fhirtypes';
import { getConfig } from '../../config';

export type ExtendedOrganization = Organization & {
  patientCount: number;
  stripeProduct: any;
  isPending: boolean;
};

const keys = {
  all: [QueryKeys.GET_ORGANIZATION_LIST] as const,
  byParams: (count: number, page: number, searchText: string) => [...keys.all, count, page, searchText] as const,
};

export const useGetOrganizationList = (
  page: number,
  count: number,
  searchText: string,
  options?: UseQueryOptions<
    any | undefined,
    Error,
    any | undefined,
    readonly [...(SearchRequest | number | string | undefined)[]]
  >
): UseQueryResult<any | undefined, Error> => {
  const medplum = useMedplum();
  const {getOrganizationList} = getConfig()

  // Initialize your query parameters
  let queryParameters = `&name:contains=${searchText}`;
  let sortingParameters = '&_sort=-_lastUpdated';

  // // Loop through the filters array to build the query string
  // if (search?.filters) {
  //   search.filters.forEach((filter: { code: any; value: any }) => {
  //     if (filter.code !== 'payment_status') {
  //       queryParameters += `&${filter.code}=${filter.value}`;
  //     }
  //   });
  // }

  // if (search?.sortRules) {
  //   search.sortRules.forEach((filter) => {
  //     if (filter.descending) {
  //       sortingParameters += `&_sort=-${filter.code}`;
  //     } else {
  //       sortingParameters += `&_sort=${filter.code}`;
  //     }
  //   });
  // }

  return useQuery<
    any | undefined,
    Error,
    any | undefined,
    readonly [...(SearchRequest | string | number | undefined)[]]
  >(
    keys.byParams(count, page, searchText),
    async () =>
      await medplum.executeBot(getOrganizationList, {
        count,
        offset: page === 1 ? 0 : count * (page - 1),
        sortingParameters,
        queryParameters,
      }),
    options
  );
};
