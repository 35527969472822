import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../../config';

export interface IActivateOrganizationProps {
  organizationId: string;
}

export const useActivateOrganization = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const {activateOrganization} = getConfig()

  return useMutation(async ({ organizationId }: IActivateOrganizationProps) => {
    return medplum.executeBot(activateOrganization, {
      organizationId,
    });
  }, options);
};
