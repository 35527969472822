export interface MedplumAppConfig {
  baseUrl?: string;
  clientId?: string;
  projectId?: string;
  googleClientId?: string;
  recaptchaSiteKey?: string;
  registerEnabled?: boolean | string;
  googlePlayStoreUrl: string;
  appStoreUrl: string;
  patientAccessPolicy?:string;
  adminPatientAccessPolicy?: string;
  ovokBaseUrl?:string;
  changeUsersGroup: string;
  deleteGroup: string;
  getStripeCoupons: string;
  getStripeProducts: string;
  updateUserAdminStatus: string;
  createStripeCustomerAndSubscription: string;
  inviteMemberByEmail: string;
  getInvoices: string;
  getOrganizationList: string;
  validateEuVatTaxId: string;
  getStripeTaxCountries: string;
  upgradeStripePlan: string;
  getSubscriptionById: string;
  deactivateOrganization: string;
  activateOrganization: string;
  sendInvitationEmail: string;
  getOverviewData: string;
  deactivateUser: string;
  activateUser: string;
}

const config: MedplumAppConfig = {
  baseUrl: import.meta.env?.MEDPLUM_BASE_URL,
  clientId: import.meta.env?.MEDPLUM_CLIENT_ID,
  projectId: import.meta.env?.MEDPLUM_PROJECT_ID,
  googleClientId: import.meta.env?.GOOGLE_CLIENT_ID,
  recaptchaSiteKey: import.meta.env?.RECAPTCHA_SITE_KEY,
  registerEnabled: import.meta.env?.MEDPLUM_REGISTER_ENABLED,
  googlePlayStoreUrl: import.meta.env?.GOOGLE_PLAY_STORE_URL,
  appStoreUrl: import.meta.env?.MEDPLUM_APP_STORE_URL,
  patientAccessPolicy: import.meta.env?.MEDPLUM_PATIENT_ACCESS_POLICY,
  adminPatientAccessPolicy: import.meta.env?.MEDPLUM_ADMIN_PATIENT_ACCESS_POLICY,
  ovokBaseUrl: import.meta.env?.MEDPLUM_OVOK_API_BASE_URL,
  changeUsersGroup:import.meta.env?.MEDPLUM_CHANGE_USERS_GROUP,
  deleteGroup:import.meta.env?.MEDPLUM_DELETE_GROUP,
  getStripeCoupons:import.meta.env?.MEDPLUM_GET_STRIPE_COUPONS,
  getStripeProducts:import.meta.env?.MEDPLUM_GET_STRIPE_PRODUCTS,
  updateUserAdminStatus:import.meta.env?.MEDPLUM_UPDATE_USER_ADMIN_STATUS,
  createStripeCustomerAndSubscription:import.meta.env?.MEDPLUM_CREATE_STRIPE_CUSTOMER_AND_SUBSCRIPTION,
  inviteMemberByEmail:import.meta.env?.MEDPLUM_INVITE_MEMBER_BY_EMAIL,
  getInvoices:import.meta.env?.MEDPLUM_GET_INVOICES,
  getOrganizationList:import.meta.env?.MEDPLUM_GET_ORGANIZATION_LIST,
  validateEuVatTaxId:import.meta.env?.MEDPLUM_VALIDATE_EU_VAT_TAX_ID,
  getStripeTaxCountries:import.meta.env?.MEDPLUM_GET_STRIPE_TAX_COUNTRIES,
  upgradeStripePlan:import.meta.env?.MEDPLUM_UPGRADE_STRIPE_PLAN,
  getSubscriptionById:import.meta.env?.MEDPLUM_GET_SUBSCRIPTION_BY_ID,
  deactivateOrganization:import.meta.env?.MEDPLUM_DEACTIVATE_ORGANIZATION,
  activateOrganization:import.meta.env?.MEDPLUM_ACTIVATE_ORGANIZATION,
  sendInvitationEmail:import.meta.env?.MEDPLUM_SEND_INVITATION_EMAIL,
  getOverviewData:import.meta.env?.MEDPLUM_OVERVIEW_DATA,
  deactivateUser:import.meta.env?.MEDPLUM_DEACTIVATE_USER,
  activateUser:import.meta.env?.MEDPLUM_ACTIVATE_USER,
};

export function getConfig(): MedplumAppConfig {
  return config;
}

export function isRegisterEnabled(): boolean {
  // Default to true
  return config.registerEnabled !== false && config.registerEnabled !== 'false';
}
