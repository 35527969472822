import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMedplum } from '@medplum/react';
import { QueryKeys } from '../query-keys';

const activeSummaryKeys = {
  all: [QueryKeys.GET_GROUPS] as const,
  byParams: () => [...activeSummaryKeys.all],
};

export const useGetGroups = (
  options?: UseQueryOptions<any | undefined, Error, any | undefined, readonly [...(string | undefined)[]]>
): UseQueryResult<any | undefined, Error> => {
  const medplum = useMedplum();

  return useQuery<any | undefined, Error, any | undefined, readonly [...(string | undefined)[]]>(
    activeSummaryKeys.byParams(),
    async () =>
      (
        (
          await medplum.search('Group', undefined, {
            cache: 'reload',
          })
        ).entry || []
      ).map((e: any) => e.resource),
    options
  );
};
