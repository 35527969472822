import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../../config';

export interface IDeleteGroupProps {
  patientIds: string[] | undefined;
}

export const useUpdateUserAdminStatus = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const { updateUserAdminStatus } = getConfig();

  return useMutation(async ({ patientIds }: IDeleteGroupProps) => {
    return medplum.executeBot(updateUserAdminStatus, {
      patientIds,
    });
  }, options);
};
